import React, {Component} from 'react';
import {Input} from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';

class DurchfuehrendeEdit extends Component{
    state = {
        Durchfuehrender:{
            vorname: '',
            nachname: '',
        },
        durchfuehrende: [],
        showInputFields: false
    }

    durchfuehrenderVornameChangedHandler = (value) => {
        const durchfuehrender = {...this.state.Durchfuehrender};
        durchfuehrender.vorname = value;
        this.setState({
            Durchfuehrender: durchfuehrender
        });
    }

    durchfuehrenderNachnameChangedHandler = (value) => {
        const durchfuehrender = {...this.state.Durchfuehrender};
        durchfuehrender.nachname = value;
        this.setState({
            Durchfuehrender: durchfuehrender
        });
    }

    saveDurchfuehrender = () => {
        axios.post(this.props.apiUri + 'Test/CreateDurchfuehrender?pVorname=' + this.state.Durchfuehrender.vorname + '&pNachname=' + this.state.Durchfuehrender.nachname, null, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
            this.setState({
                Durchfuehrender:{
                    vorname: '',
                    nachname: '',
                }
            });
        this.props.newDurchfuehrenderCreated();
        }); 
    }

    render(){
        const style = {
            display: 'block ruby',
            padding: '10px'
        };
        let durchfuehrenderObject = {...this.state.Durchfuehrender};
        let ButtonText = 'Durchführenden anlegen';
        let HeaderText = 'Neuen Durchführenden anlegen'

        let saveDurchfuehrendenButton = (
            <div>
                <button
                    className="btn btn-primary"
                    onClick={() => this.saveDurchfuehrender()}
                    type="button"> {ButtonText}
                </button>
            </div>
        );

        let durchfuehrenderInputFields = (
            <div>
                <div style={style}>
                    <h5 style={style}>Vorname:</h5>
                    <Input className="w-50" type="text" id="durchfuehrenderVornameInput" value={durchfuehrenderObject.vorname} onChange={(e) => this.durchfuehrenderVornameChangedHandler(e.target.value)}/>
                </div>
                <div style={style}>
                    <h5 style={style}>Nachname:</h5>
                    <Input className="w-50" type="text" id="durchfuehrenderNachnameInput" value={durchfuehrenderObject.nachname} onChange={(e) => this.durchfuehrenderNachnameChangedHandler(e.target.value)}/>
                </div>
                <br/>
                {saveDurchfuehrendenButton}
            </div>
        );
        
        return (
            <div>
                <h5>{HeaderText}</h5>
                <br/>
                {durchfuehrenderInputFields}
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        apiUri: state.apiUri,
        token: state.token,
        username: state.username
    };
  };

export default connect(mapStateToProps)(DurchfuehrendeEdit);