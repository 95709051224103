import React from 'react';
import { Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';

const SpruengeErgebnisInput = (props) => {
    const style = {
        display: 'block ruby',
        padding: '10px'
    };
    return (
        <div>
            <div style={style}>
                <h5 style={style}>Versuch 1:</h5>
                <InputGroup className="w-50">
                    <Button onClick={() => props.onChange(1, props.SpruengeErgebnis[0] - 1)}>-</Button>
                    <Input  type="number" id="versuch1Input" value={props.SpruengeErgebnis[0]} onChange={(e) => props.onChange(1, e.target.value)}/>
                    <Button onClick={() => props.onChange(1, props.SpruengeErgebnis[0] + 1)}>+</Button>
                </InputGroup>
                <h5 style={style}>Versuche</h5>
            </div>
            <div style={style}>
                <h5 style={style}>Versuch 2:</h5>
                <InputGroup className="w-50">
                    <Button onClick={() => props.onChange(2, props.SpruengeErgebnis[1] - 1)}>-</Button>
                    <Input type="number" id="versuch1Input" value={props.SpruengeErgebnis[1]} onChange={(e) => props.onChange(2, e.target.value)}/>
                    <Button onClick={() => props.onChange(2, props.SpruengeErgebnis[1] + 1)}>+</Button>
                </InputGroup>
                <h5 style={style}>Versuche</h5>
            </div>
        </div>
    );
};

export default SpruengeErgebnisInput;