import React, {Component} from 'react';
import Select from 'react-select';
import {Table, Input, FormGroup, Label, Button, ButtonGroup, ButtonToggle} from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap'
import { getAuthToken } from '../../store/utility';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

class Auswertung extends Component{
    state = {
        auswertungType: 1,
        testtype: 1,
        kitas: [],
        tests: [],
        kinder: [],
        testErgebnisse: [],
        selectedKitas: [],
        selectedTests: [],
        kitajahre: [],
        selectedKitajahr: null,
        KitanamenSwitchEnabled: false,
        KindernamenSwitchEnabled: false,
        KinderAgeEnabled: false,
        KinderGenderEnabled: false,
        WerteSelected: true,
        
        showKitajahrSelect: false,
        showKitaSelect: false,
        showResultType: false,
        showSettings: false,
        showIncludePretestCheckbox: false,
    }

    constructor(props) {
        super(props);
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Test/GetKitajahre?pTestTypeID=' + this.state.testtype, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(response => {
                this.setState({
                    kitajahre: response.data,
                    selectedKitajahr: response.data[response.data.length - 1]
                }, () => {
                    this.KitajahrChangedHandler(this.state.selectedKitajahr)
                });
            });
    }

    //componentDidMount(){
    //    axios.get(this.props.apiUri + 'Test/GetKitajahre?pTestTypeID=' + this.state.testtype, {headers: {'Authorization': `Bearer ${this.props.token}`}})
    //        .then(response => {
    //            this.setState({
    //                kitajahre: response.data,
    //                selectedKitajahr: response.data[response.data.length - 1]
    //            }, () => {
    //                this.KitajahrChangedHandler(this.state.selectedKitajahr)
    //            });
    //        });
    //}

    setWerteSelected = (value) => {
        this.setState({
            WerteSelected: value
        });
    }

    setAuswertungType = (selected) => {
        this.setState({
            auswertungType: selected
        });
    };

    setTestType = (selected) => {
        this.setState({
            testtype: selected,
            selectedKitas: [],
            selectedKitajahr: null
        }, () => {
            axios.get(this.props.apiUri + 'Test/GetKitajahre?pTestTypeID=' + selected, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                this.setState({kitajahre: response.data});
            });
        });
    };

    KitajahrChangedHandler = value => {
        this.setState({
            selectedKitajahr: value,
            selectedKitas: []
        }, () => {
            if(this.state.selectedKitajahr != null){
                axios.get(this.props.apiUri + 'Kita/GetKitasWithTestsInYear?pKitajahr=' + this.state.selectedKitajahr.bezeichnung + '&pTestTypeID=' + this.state.testtype, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                .then(response => {
                    this.setState({kitas: response.data});
                });
            }
        });
    }

    KitaChangedHandler = value => {
        if(value === null){
            value = [];
        }
        this.setState({
            selectedKitas: value
        });
    }

    toggleKitaNamenSwitch = () => {
        this.setState(prevState => ({
            KitanamenSwitchEnabled: !prevState.KitanamenSwitchEnabled
          }));
    }

    toggleKinderNamenSwitch = () => {
        this.setState(prevState => ({
            KindernamenSwitchEnabled: !prevState.KindernamenSwitchEnabled
          }));
    }

    toggleKinderAgeSwitch = () => {
        this.setState(prevState => ({
            KinderAgeEnabled: !prevState.KinderAgeEnabled
          }));
    }

    toggleKinderGenderSwitch = () => {
        this.setState(prevState => ({
            KinderGenderEnabled: !prevState.KinderGenderEnabled
          }));
    }

    AuswertungErstellen = () => {
        let kitaIds = "";
        this.state.selectedKitas.map(function(item) {
            kitaIds = kitaIds.concat(item['id']+',');
        });
        kitaIds = kitaIds.slice(0, -1);
        console.log('kitaIds: ', kitaIds);
        if(this.state.WerteSelected){
            axios({
                url: this.props.apiUri + 'Auswertung/GetEinzelauswertungWerte' ,
                params: {
                    'pKitajahr': this.state.selectedKitajahr.bezeichnung, 
                    'pTestTypeId': this.state.testtype, 
                    'pKitaIds': kitaIds,
                    'pShowKitaname': this.state.KitanamenSwitchEnabled,
                    'pShowKindname': this.state.KindernamenSwitchEnabled,
                    'pShowGender': this.state.KinderGenderEnabled,
                    'pShowAge': this.state.KinderAgeEnabled
                 },
                method: 'GET',
                responseType: 'blob', // important
                headers: {'Authorization': `Bearer ${this.props.token}`}
              }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "AuswertungWerte" + '.csv');
                document.body.appendChild(link);
                link.click();
              });
        }
        else{
            axios({
                url: this.props.apiUri + 'Auswertung/GetEinzelauswertungBewertung' ,
                params: {
                    'pKitajahr': this.state.selectedKitajahr.bezeichnung, 
                    'pTestTypeId': this.state.testtype, 
                    'pKitaIds': kitaIds,
                    'pShowKitaname': this.state.KitanamenSwitchEnabled,
                    'pShowKindname': this.state.KindernamenSwitchEnabled,
                    'pShowGender': this.state.KinderGenderEnabled,
                    'pShowAge': this.state.KinderAgeEnabled
                 },
                method: 'GET',
                responseType: 'blob', // important
                headers: {'Authorization': `Bearer ${this.props.token}`}
              }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "AuswertungBewertung" + '.csv');
                document.body.appendChild(link);
                link.click();
              });
        }
        
    }

    render(){
        let AuswertungTypeSelection =(
            <div>
                <h3>Auswertung</h3>
                <ButtonGroup>
                    <Button outline size="md" color="primary" onClick={() => this.setAuswertungType(1)} active={this.state.auswertungType === 1}>EINZELAUSWERTUNG</Button>
                    <Button outline size="md" disabled="True" color="primary" onClick={() => this.setAuswertungType(2)} active={this.state.auswertungType === 2}>PRE- UND POSTTESTVERGLEICH</Button>
                    <Button outline size="md" disabled="True" color="primary" onClick={() => this.setAuswertungType(3)} active={this.state.auswertungType === 3}>JAHRESSTATISTIK</Button>
                </ButtonGroup>
            </div>
        );

        let TestTypeSelection = (
            <div>
                <h3>Testtyp</h3>
                <ButtonGroup>
                    <Button outline size="md" color="primary" onClick={() => this.setTestType(1)} active={this.state.testtype === 1}>PRETEST</Button>
                    <Button outline size="md" color="primary" onClick={() => this.setTestType(2)} active={this.state.testtype === 2}>POSTTEST</Button>
                    <Button outline size="md" color="primary" disabled="True" onClick={() => this.setTestType(3)} active={this.state.testtype === 3}>FREIER TEST</Button>
                </ButtonGroup>
            </div>
        );
        let SchuljahrSelection = null;
        if(this.state.testtype != null){
            SchuljahrSelection = (
                <div>
                    <h3>Kitajahr</h3>
                    <Select 
                            id="Kitajahr"
                            getOptionLabel={option => `${option.bezeichnung}`}
                            getOptionValue={option => option.id}
                            options={this.state.kitajahre}
                            onChange={(value) => this.KitajahrChangedHandler(value)}
                            value={this.state.selectedKitajahr}
                            isClearable='true'
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'} />
              </div>
            );
        }

        const BezirkLabel = data => (
            <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
          </div>
        );

        let KitaSelection = null;
        if(this.state.selectedKitajahr != null){
            KitaSelection = (
                <div>
                    <h3>Kitas</h3>
                    <Select
                        id="kitas"
                        defaultValue={[]}
                        value={this.state.selectedKitas}
                        getOptionLabel={option => `${option.name}`}
                        getOptionValue={option => option.id}
                        onChange={(value) => this.KitaChangedHandler(value)}
                        isMulti
                        closeMenuOnSelect={false}
                        options={this.state.kitas}
                        classNamePrefix="select"
                        formatGroupLabel={BezirkLabel}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        isClearable='true'
                    />
                </div>
            );
        }
         
        let Switches = null;
        let createAuswertungButton = null;
        if(typeof this.state.selectedKitas !== 'undefined' && this.state.selectedKitas.length > 0)
        {
            Switches = (
                <div>
                    <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                        <h4>Einstellungen</h4>
                    </Form.Label>
                    <Form inline>
                        <Form.Check 
                            type="switch"
                            className="my-1 mr-sm-2"
                            id="KitaNameSwitch"
                            label="Kitaname"
                            checked={this.state.KitanamenSwitchEnabled}
                            onChange={this.toggleKitaNamenSwitch}
                        />
                        <Form.Check 
                            type="switch"
                            className="my-1 mr-sm-2"
                            id="KindNameSwitch"
                            label="Kindername"
                            checked={this.state.KindernamenSwitchEnabled}
                            onChange={this.toggleKinderNamenSwitch}
                        />
                        <Form.Check 
                            type="switch"
                            className="my-1 mr-sm-2"
                            id="ageSwitch"
                            label="Alter"
                            checked={this.state.KinderAgeEnabled}
                            onChange={this.toggleKinderAgeSwitch}
                        />
                        <Form.Check 
                            type="switch"
                            className="my-1 mr-sm-2"
                            id="genderSwitch"
                            label="Geschlecht"
                            checked={this.state.KinderGenderEnabled}
                            onChange={this.toggleKinderGenderSwitch}
                        />
                    </Form>
                    <Form inline>
                        <FormGroup check>
                        <Input 
                            type="radio" 
                            className="my-1 mr-sm-2"
                            id="WerteRadio" 
                            name="werteRadio" 
                            label="Werte" 
                            checked={this.state.WerteSelected}
                            onChange={() => this.setWerteSelected(true)}
                            />
                            <Label check>Werte</Label>
                        </FormGroup>
                        <FormGroup check>
                        <Input 
                            type="radio" 
                            className="my-1 mr-sm-2"
                            id="BewertungRadio" 
                            name="bewertungRadio" 
                            label="Bewertung" 
                            checked={!this.state.WerteSelected}
                            onChange={() => this.setWerteSelected(false)}
                            />
                        <Label check>Bewertung</Label>
                        </FormGroup>
                    </Form>
                </div>
            );

            createAuswertungButton = (
                <div>
                    <button
                        className="btn btn-primary"
                        onClick={() => this.AuswertungErstellen()}
                        type="button">
                            Auswertung erstellen
                    </button>
                </div>
            );
        }

        return(
            <div><Form>
                {AuswertungTypeSelection}
                <br/>
                {TestTypeSelection}
                <br/>
                {SchuljahrSelection}
                <br/>
                {KitaSelection}
                <br/>
                {Switches}
                <br/>
                {createAuswertungButton}
                </Form>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        apiUri: state.apiUri,
        token: state.token,
        username: state.username
    };
  };

export default connect(mapStateToProps)(Auswertung);