import React, { Component } from 'react';
import Select from 'react-select';
import { Route, Routes, useLocation, useNavigate, useParams, Navigate, Link} from 'react-router-dom';
import axios from 'axios';
import {Input, Label} from 'reactstrap';
import { connect } from 'react-redux';
import { getAuthToken } from '../../store/utility';

class StartTest extends Component {
    state = {
        durchfuehrende: [],
        newTest: null,
        kitas: [],
        testTypes: [],
        pretests: [],
        unabgeschlosseneTests: [],
        selectedUnfishedTest: null,
        selectedKita: null,
        selectedTestType: null,
        selectedPretest: null,
        redirect: false,
        showTestNewTestSwitch: false,
        showTestType: false,
        showKitas: false,
        showPretests: false,
        showBeschreibungAndButtonAfterUnfinishedTestSelected: false,
        showTestBeschreibung: false,
        testBeschreibung: '',
        durchfuehrender: null,
        createdTestID: null
    };

    constructor(props) {
        super(props);
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Kita', { headers: { 'Authorization': `Bearer ${token}` } })
            .then(response => {
                this.setState({ kitas: response.data });
            }).then(
                axios.get(this.props.apiUri + 'Test/GetTestTypes', { headers: { 'Authorization': `Bearer ${token}` } })
                    .then(response => {
                        this.setState({ testTypes: response.data });
                    }).then(
                        axios.get(this.props.apiUri + 'Test/GetAllDurchfuehrende', { headers: { 'Authorization': `Bearer ${token}` } })
                            .then(response => {
                                this.setState({ durchfuehrende: response.data })
                            })
                    ));
    }

    //componentDidMount() {
    //    axios.get(this.props.apiUri + 'Kita', {headers: {'Authorization': `Bearer ${this.props.token}`}})
    //        .then(response => {
    //            this.setState({kitas: response.data});
    //        }).then(
    //            axios.get(this.props.apiUri + 'Test/GetTestTypes', {headers: {'Authorization': `Bearer ${this.props.token}`}})
    //                .then(response => {
    //                    this.setState({testTypes: response.data});
    //        }).then(
    //            axios.get(this.props.apiUri + 'Test/GetAllDurchfuehrende', {headers: {'Authorization': `Bearer ${this.props.token}`}})
    //                .then(response => {
    //                    this.setState({durchfuehrende: response.data})
    //                })
    //        ));
    //}

    LoadPretestsOfKita = (id) => {
        axios.get(this.props.apiUri + 'Test/GetOpenPretestsOfKita?pKitaID=' + id, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                this.setState({
                    pretests: response.data
                });
            });
    }

    pretestChangeHandler = selectedPretest => {
        this.setState({
            selectedPretest: selectedPretest
        });
    }

    unfinishedTestChangeHandler = selectedUnfinishedTest => {
        this.setState({
            selectedUnfinishedTest: selectedUnfinishedTest,
            showBeschreibungAndButtonAfterUnfinishedTestSelected: true
        });
    }

    testTypeChangedHandler = selectedTestType => {
        let showKitas = true;
        let testType = selectedTestType;
        if(testType.id === 2){
            if(this.state.selectedKita !== null){
                this.LoadPretestsOfKita(this.state.selectedKita.id);
            }
        }
        if(testType.id === 3){
            showKitas = false;
        }
        this.setState({
            selectedTestType: testType,
            showKitas: showKitas
        });
    }

    kitaChangeHandler = selectedKita => {
        let selectedTestType = {...this.state.selectedTestType}
        let showPretests = false;
        if(selectedKita !== null && selectedTestType.id === 2){
            this.LoadPretestsOfKita(selectedKita.id);
            showPretests = true;
        }
        this.setState({
            selectedKita: selectedKita,
            showPretests: showPretests  
        });
    };

    testBeschreibungChangedHandler = testBeschreibung => {
        this.setState({
            testBeschreibung: testBeschreibung
        });
    }

    durchfuehrenderChangedHandler = durchfuehrender => {
        if(durchfuehrender !== null){
            this.setState(
                {
                    durchfuehrender: durchfuehrender,
                    showTestNewTestSwitch: true
                }
            );
        }
        else{
            this.setState(
                {
                    newTest: null,
                    durchfuehrender: null,
                    showTestNewTestSwitch: false,
                    selectedKita: null,
                    selectedTestType: null,
                    selectedPretest: null,
                    showKitas: false,
                    showPretests: false,
                    showTestBeschreibung: false,
                    showBeschreibungAndButtonAfterUnfinishedTestSelected: false
                }
            );
        }
    }

    newTest = () => {
        this.setState({
            newTest: true,
            showBeschreibungAndButtonAfterUnfinishedTestSelected: false
        });
    }

    continueTest = () => {
        axios.get(this.props.apiUri + 'Test/GetUnfinishedTests?pDurchfuehrenderID=' + this.state.durchfuehrender.id, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                let unfinishedTests = response.data;
                this.setState({
                    newTest: false,
                    showUnfinishedTests: true,
                    unabgeschlosseneTests: unfinishedTests
                });
            });
    }

    testStarten = () => {
        if(this.state.selectedTestType.id === 1){
            // https://localhost:44342/api/Test/CreateTest?pTestTypeID=1&pBeschreibung=Dies%20ist%20ein%20Pretest%20aus%20swagger%20UI&pDurchfuehrenderID=1&pKitaID=2
            axios.post(this.props.apiUri + 'Test/CreateTest?pTestTypeID=1&pBeschreibung=' + this.state.testBeschreibung +  '&pDurchfuehrenderID=' + this.state.durchfuehrender.id + '&pKitaID=' + this.state.selectedKita.id, null, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                .then(response => {
                    this.props.navigate("/test", { state: { testID: response.data.id } });
            });
        }
        if(this.state.selectedTestType.id === 2){
            const Pretestid = (this.state.selectedPretest === null) ? 0 : this.state.selectedPretest.id
            console.log(Pretestid)
            axios.post(this.props.apiUri + 'Test/CreateTest?pTestTypeID=2&pBeschreibung=' + this.state.testBeschreibung + '&pDurchfuehrenderID=' + this.state.durchfuehrender.id + '&pPretestID=' + Pretestid + '&pKitaID=' + this.state.selectedKita.id, null, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                this.props.navigate("/test", { state: { testID: response.data.id } });
            });
        }
        if(this.state.selectedTestType.id === 3){
            axios.post(this.props.apiUri + 'Test/CreateTest?pTestTypeID=3&pBeschreibung=' + this.state.testBeschreibung + '&pDurchfuehrenderID=' + this.state.durchfuehrender.id, null, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                .then(response => {
                    this.props.navigate("/test", { state: { testID: response.data.id } });
                });
        }
    };

    testWeiterführen = () => {
        console.log("Test weiterführen");
        let selectedUnfinishedTest = {...this.state.selectedUnfinishedTest};
        this.setState({
            selectedUnfinishedTest: selectedUnfinishedTest,
        });
        this.props.navigate("/test", { state: { testID: selectedUnfinishedTest.id } });
    }

    renderRedirect = () => {
        let createdTestID = 0;
        if (this.state.redirect) {
            if (this.state.newTest) {
                createdTestID = this.state.createdTestID;
            }
            else {
                createdTestID = this.state.selectedUnfinishedTest.id;
            }
            console.log("testID: " + createdTestID);
            return 
            <Navigate
                to={{
                    pathname: '/test',
                    state: {
                        testID: createdTestID
                    },
                }}
            />
        }
    }

    getTestLabel = (test) => {
        return <div><p>{test.id} - {test.beginn}</p></div>
    } 

    render() {
        let { durchfuehrender, testBeschreibung, selectedKita, selectedPretest, selectedTestType, selectedUnfinishedTest} = this.state;
        const durchfuehrende = [...this.state.durchfuehrende];
        const kitas = [...this.state.kitas];
        const testTypes = [...this.state.testTypes];
        const pretests = [...this.state.pretests];
        const unabgeschlosseneTests = [...this.state.unabgeschlosseneTests];
        
        
        let durchfuehrenderInput = (
            <div>
                <Label for="durchfuehrender">Durchführender:</Label>
                <Select
                    id="durchfuehrender"
                    getOptionLabel = {option => `${option.vorname} ${option.nachname}`}
                    getOptionValue = {option => option.id}
                    options = {durchfuehrende}
                    onChange = {(event) => this.durchfuehrenderChangedHandler(event)}
                    value={durchfuehrender}
                    isClearable={true}/>
                <br/>
            </div>
        );
        
        let newTestSwitch = null;
        if(this.state.showTestNewTestSwitch){
            newTestSwitch = (
                <div>
                    <button
                            className="btn btn-primary"
                            onClick={() => this.newTest()}
                            type="button">
                                Neuen Test starten
                    </button>
                    <button
                            className="btn btn-primary"
                            onClick={() => this.continueTest()}
                            type="button">
                                Angefangenen Test weiterführen
                    </button>
                </div>
            );
        }
        let kitaList = null;
        let testTypeList = null;
        let offenePretestsList = null;
        let followUpButton = null;
        let followUpButtonText = 'Neuer Test'
        let showFollowUpButton = false;
        let unfinishedTestsList = null;
        let showContinueTestButton = false;
        if(this.state.newTest){
            // neuer Test mit 
            // Test typ liste
            testTypeList = (
                <div>
                    <h4>Wählen Sie eine Testart aus: </h4>
                    <Select
                        getOptionLabel = {option => option.bezeichnung}
                        getOptionValue = {option => option.id}
                        options = {testTypes}
                        onChange = {(event) => this.testTypeChangedHandler(event)}
                        value={selectedTestType}
                        isClearable={false}/>
                </div>
            );

            // Kita liste, wenn ein Testtyp festgelegt wurde
            if(this.state.showKitas){
                kitaList = (
                    <div>
                        <h4>Wählen Sie eine Kita aus: </h4>
                        <Select
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            options={kitas}
                            onChange={(event) => this.kitaChangeHandler(event)}
                            value={selectedKita}
                            isClearable='true'/>
                    </div>
                );
            }

            // Weiter Button zeigen, wenn Test type ausgewählt wurde ...
            if (selectedTestType !== null) {

                // ... und es ein Pretest ist
                if (selectedTestType.id === 1) {
                    followUpButtonText = 'Pretest starten';
                }

                // Posttest liste, wenn Test typ = 2 => Posttest
                if (selectedTestType.id === 2) {
                    followUpButtonText = 'Posttest starten';
                    offenePretestsList = (
                        <div>
                            <h5>Offene Pretests dieser Kita: </h5>
                            <Select
                                getOptionLabel={option => `${option.id} ${new Date(option.beginn).toLocaleString()}`}
                                getOptionValue={option => option.id}
                                options={pretests}
                                value={selectedPretest}
                                onChange={(event) => this.pretestChangeHandler(event)}
                                isClearable={true} />
                        </div>
                    );
                }

                // ... und eine Beschreibung eingegeben wurde
                if (this.state.testBeschreibung != '') {
                    if (selectedTestType.id === 3) {
                        showFollowUpButton = true;
                        followUpButtonText = 'Freien Test starten'
                    }

                    if (this.state.selectedKita !== null) {
                        showFollowUpButton = true;
                    }
                }
            }
            
        }

        else{
            // test weiterführen mit
            if(this.state.showUnfinishedTests){
                // Unabgeschlossene Tests Liste
                unfinishedTestsList = (
                    <div>
                        <h4>Wählen Sie einen unabgeschlossenen Test aus: </h4>
                        <Select
                            getOptionLabel={option => `${option.testtyp} - ${option.kitaName} - ${new Date(option.beginn).toLocaleString()}`}
                            getOptionValue={option => option.id}
                            options={unabgeschlosseneTests}
                            onChange={(event) => this.unfinishedTestChangeHandler(event)}
                            value={selectedUnfinishedTest}
                            isClearable='true'/>
                    </div>
                );

                if(this.state.showBeschreibungAndButtonAfterUnfinishedTestSelected){
                    testBeschreibung = selectedUnfinishedTest.beschreibungDavor;

                    // followUpButton mit Text "Test weiterführen"
                    showContinueTestButton = true;
                    followUpButtonText = 'Test weiterführen'
                }                
            }

        }

        let beschreibungInput = null;
        beschreibungInput = (
            <div>
                <Label for="textBeschreibung">Testbeschreibung:</Label>
                <Input 
                    type="textarea" 
                    name="Beschreibung" 
                    id="textBeschreibung" 
                    value={testBeschreibung} 
                    onChange={(e) => this.testBeschreibungChangedHandler(e.target.value)}
                    />
            </div>
        );

        // if(selectedPretest !== null){
        //     showFollowUpButton = true;
        // }

        if(showFollowUpButton){
            followUpButton = (
                <div><button
                            className="btn btn-primary"
                            onClick={() => this.testStarten()}
                            type="button">
                                {followUpButtonText}
                        </button>
                        </div>
            );
        }  
        
        if(showContinueTestButton){
            followUpButton = (
                <div><button
                            className="btn btn-primary"
                            onClick={() => this.testWeiterführen()}
                            type="button">
                                {followUpButtonText}
                        </button>
                        </div>
            );
        }

        return (
            <div>
                <h3>Motorik-Test starten</h3>
                <br/>
                {durchfuehrenderInput}
                <br/>
                {newTestSwitch}
                <br/>
                {testTypeList}
                {unfinishedTestsList}
                <br />
                {kitaList}
                <br/>
                {offenePretestsList}
                <br />
                {beschreibungInput}
                <br />
                <div>
                    {followUpButton}
                </div>
            </div>
        );
    };
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
        <Component
            {...props}
            location={location}
            params={params}
            navigate={navigate}
        />
    );
  }

  return ComponentWithRouterProp;
}

const mapStateToProps = state => {
    return {
        apiUri: state.apiUri,
        token: state.token,
        username: state.username
    };
  };

export default withRouter(connect(mapStateToProps)(StartTest));