import React, {Component} from 'react';
import { Col, Container, Row, Input, InputGroup, Button, Alert } from 'reactstrap';
import TestUserList from '../TestUserList';
import {FaCheckCircle } from 'react-icons/fa';
import axios from 'axios';
import { connect } from 'react-redux';
import { getAuthToken } from '../../../store/utility';
import StandweitsprungErgebnisInput from './StandweitsprungErgebnisInput';

class Standweitsprung extends Component {
    state = {
        showSaveButton: true,
        showSuccessAlert: false,
        standweitsprungErgebnisse: [],
        StandweitsprungErgebnis: [90,90]
    }

    componentWillReceiveProps() {
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Standweitsprung/GetStandweitsprungErgebnisseOfTest?pTestID=' + this.props.testID, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => {
                this.setState({
                    standweitsprungErgebnisse: response.data
                });
            });
    }
    componentDidMount() {
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Standweitsprung/GetStandweitsprungErgebnisseOfTest?pTestID=' + this.props.testID, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => {
                this.setState({
                    standweitsprungErgebnisse: response.data
                });
            });
    }

    StandweitsprungErgebnisChangedHandler = (versuch, value) =>{
        let newStandweitsprungErgebnis = [...this.state.StandweitsprungErgebnis];
        newStandweitsprungErgebnis[versuch - 1] = parseInt(value,10);
        this.setState({
            StandweitsprungErgebnis: newStandweitsprungErgebnis
        });
    }

    activeTeilnehmerChangedHandler = (selected) => {
        let newStandweitsprungErgebnis = [90,90]
        this.setState({
            StandweitsprungErgebnis: newStandweitsprungErgebnis,
            showSaveButton: true,
            showSuccessAlert: false
        });
        this.props.changed(selected);
    }

    saveStandweitsprungErgebnis = () => {
        const data = [...this.state.StandweitsprungErgebnis];
        axios.post(this.props.apiUri + 'Standweitsprung?pKindID=' + this.props.activeTeilnehmerID + '&pTestID=' + this.props.testID + '', data, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                axios.get(this.props.apiUri + 'Standweitsprung/GetStandweitsprungErgebnisseOfTest?pTestID=' + this.props.testID, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                    .then(response => {
                        this.setState({
                            standweitsprungErgebnisse: response.data,
                            showSaveButton: false,
                            showSuccessAlert: true
                        })
                    })
            });
    }

    render() {
        let standweitsprungErgebnisse = [...this.state.standweitsprungErgebnisse];
        let versuch1Ergebnis = this.state.StandweitsprungErgebnis[0];
        let versuch2Ergebnis = this.state.StandweitsprungErgebnis[1];
        let showSaveButton = this.state.showSaveButton;
        let showSuccessAlert = this.state.showSuccessAlert;

        let standweitsprungAnleitung = (
            <div>
                <p>Standweitsprung</p>
                <p>Setzen Sie das Ergbnis des jeweiligen Versuchs in das Feld ein</p>
            </div>
        );

        let standweitsprungTeilnehmer = [...this.props.testUsers];
        standweitsprungTeilnehmer.forEach(function (testUser) {
            if (standweitsprungErgebnisse.length > 0 && standweitsprungErgebnisse.some(ergebnis => ergebnis.kindID === testUser.id)) {
                testUser.uebungAbsolviertSymbol = <FaCheckCircle size={16} />;
                testUser.uebungAbsolviert = "JA";
            }
            else {
                testUser.uebungAbsolviert = "NEIN";
                testUser.uebungAbsolviertSymbol = "";
            }
        });

        let index = [...this.props.selected];
        if (standweitsprungTeilnehmer !== undefined && standweitsprungTeilnehmer[index[0]] !== undefined) {
            if (standweitsprungTeilnehmer[index[0]].uebungAbsolviert === "JA") {
                showSaveButton = false;
            }
        }

        let saveStandweitsprungErgebnisButton = null;
        let standweitsprungErgebnisInput = null;
        if (showSaveButton) {
            standweitsprungErgebnisInput = (
                <div>
                    <StandweitsprungErgebnisInput
                        StandweitsprungErgebnis={this.state.StandweitsprungErgebnis}
                        onChange={(versuch, value) => this.StandweitsprungErgebnisChangedHandler(versuch, value)} />
                </div>
            );
            saveStandweitsprungErgebnisButton = (
                <div>
                    <button
                        className="btn btn-primary"
                        onClick={() => this.saveStandweitsprungErgebnis()}
                        type="button"> Ergebnis speichern
                    </button>
                </div>
            );
        }
        else {
            let currentUserStandweitsprungErgebnis = standweitsprungErgebnisse.filter(ergebnis => ergebnis.kindID === this.props.activeTeilnehmerID);
            const style = {
                display: 'block ruby',
                padding: '10px'
            };
            if (currentUserStandweitsprungErgebnis.length > 0) {
                standweitsprungErgebnisInput = (
                    <div>
                        <h4>Gespeicherte Ergebnisse</h4>
                        <h5 style={style}>Versuch 1: {currentUserStandweitsprungErgebnis[0].versuch1}</h5>
                        <h5 style={style}>Versuch 2: {currentUserStandweitsprungErgebnis[0].versuch2}</h5>
                    </div>
                );
            }
        }

        if (showSuccessAlert) {
            saveStandweitsprungErgebnisButton = (
                <Alert color="success">Standweitsprung Ergebnis von {this.props.activeTeilnehmerVorname} wurde gespeichert!</Alert>
            );
        }

        return (
            <div>
                <Container>
                    <Row>
                        <Col xs="3">
                            <TestUserList
                                id="standweitsprungTeilnehmer"
                                testUsers={standweitsprungTeilnehmer}
                                selected={this.props.selected}
                                changed={(selected) => { this.activeTeilnehmerChangedHandler(selected) }} />
                        </Col>
                        <Col>
                            <h3>{this.props.activeTeilnehmerVorname} führt folgende Aufgabe durch:</h3>
                            {standweitsprungAnleitung}
                            {standweitsprungErgebnisInput}
                            {saveStandweitsprungErgebnisButton}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };    
};

const mapStateToProps = state => {
    return {
        apiUri: state.apiUri,
        token: state.token,
        username: state.username
    };
  };

export default connect(mapStateToProps)(Standweitsprung);