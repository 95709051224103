import React, {Component} from 'react';
import { Col, Container, Row, Input, InputGroup, Button, Alert } from 'reactstrap';
import NumericInput from 'react-numeric-input';
import TestUserList from '../TestUserList';
import {FaCheckCircle } from 'react-icons/fa';
import axios from 'axios';
import { connect } from 'react-redux';
import { getAuthToken } from '../../../store/utility';
import RumpfbeugeErgebnisInput from './RumpfbeugeErgebnisInput';

class Rumpfbeuge extends Component {
    state = {
        showSaveButton: true,
        showSuccessAlert: false,
        rumpfbeugeErgebnisse: [],
        RumpfbeugeErgebnis: ["0.0","0.0"]
    }

    componentWillReceiveProps() {
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Rumpfbeuge/GetRumpfbeugeErgebnisseOfTest?pTestID=' + this.props.testID, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => {
                this.setState({
                    rumpfbeugeErgebnisse: response.data
                });
            });
    }

    componentDidMount() {
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Rumpfbeuge/GetRumpfbeugeErgebnisseOfTest?pTestID=' + this.props.testID, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => {
                this.setState({
                    rumpfbeugeErgebnisse: response.data
                });
            });
    }

    RumpfbeugeErgebnisChangedHandler = (versuch, value) =>{
        // console.log("valueChanged: ", value);
        if (value === "") {
            value = "0.0";
        }
        let newRumpfbeugeErgebnis = [...this.state.RumpfbeugeErgebnis];
        if (value === "-") {
            newRumpfbeugeErgebnis[versuch - 1] = value;
        }
        else {
            newRumpfbeugeErgebnis[versuch - 1] = parseFloat(parseFloat(value).toFixed(1));
        }
        this.setState({
            RumpfbeugeErgebnis: newRumpfbeugeErgebnis
        });
    }

    activeTeilnehmerChangedHandler = (selected) => {
        let newRumpfbeugeErgebnis = ["0.0","0.0"]
        this.setState({
            RumpfbeugeErgebnis: newRumpfbeugeErgebnis,
            showSaveButton: true,
            showSuccessAlert: false
        });
        this.props.changed(selected);
    }

    saveRumpfbeugeErgebnis = () => {
        const data = [...this.state.RumpfbeugeErgebnis];
        axios.post(this.props.apiUri + 'Rumpfbeuge?pKindID=' + this.props.activeTeilnehmerID + '&pTestID=' + this.props.testID + '', data, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                axios.get(this.props.apiUri + 'Rumpfbeuge/GetRumpfbeugeErgebnisseOfTest?pTestID=' + this.props.testID, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                    .then(response => {
                        this.setState({
                            rumpfbeugeErgebnisse: response.data,
                            showSaveButton: false,
                            showSuccessAlert: true
                        });
                    })
            })
    }

    render() {
        let rumpfbeugeErgebnisse = [...this.state.rumpfbeugeErgebnisse];
        let showSaveButton = this.state.showSaveButton; // wird überschrieben, wenn Ergebnis bereits vorhanden ist

        let rumpfbeugeAnleitung = (
            <div>
                <p>Rumpfbeugen</p>
                <p>Setzen Sie das Ergebnis des jeweiligen Versuchs in das Feld ein</p>
            </div>
        );

        let rumpfbeugeTeilnehmer = [...this.props.testUsers];
        rumpfbeugeTeilnehmer.forEach(function (testUser) {
            if (rumpfbeugeErgebnisse.length > 0 && rumpfbeugeErgebnisse.some(ergebnis => ergebnis.kindID === testUser.id)) {
                testUser.uebungAbsolviertSymbol = <FaCheckCircle size={16} />;
                testUser.uebungAbsolviert = "JA";
            }
            else {
                testUser.uebungAbsolviert = "NEIN";
                testUser.uebungAbsolviertSymbol = "";
            }
        });

        let index = [...this.props.selected];
        if (rumpfbeugeTeilnehmer !== undefined && rumpfbeugeTeilnehmer[index[0]] !== undefined) {
            if (rumpfbeugeTeilnehmer[index[0]].uebungAbsolviert === "JA") {
                showSaveButton = false;
            }
        }

        let saveRumpfbeugeErgebnisButton = null;
        let rumpfbeugeErgebnisInput = null;

        if (showSaveButton) {
            // Zu diesem Kind wurde noch kein Rumpfbeuge Ergebnis gespeichert
            rumpfbeugeErgebnisInput = (
                <div>
                    <RumpfbeugeErgebnisInput
                        RumpfbeugeErgebnis={this.state.RumpfbeugeErgebnis}
                        onChange={(number, value) => this.RumpfbeugeErgebnisChangedHandler(number, value)}
                    />
                </div>
            );

            saveRumpfbeugeErgebnisButton = (
                <div><button
                    className="btn btn-primary"
                    onClick={() => this.saveRumpfbeugeErgebnis()}
                    type="button"> Ergebnis speichern
                </button>
                </div>
            );
        }
        else {
            // Es wurde bereits ein Rumpfbeuge Ergebnis gespeichert
            let currentUserRumpfbeugeErgebnis = rumpfbeugeErgebnisse.filter(ergebnis => ergebnis.kindID === this.props.activeTeilnehmerID);
            const style = {
                display: 'block ruby',
                padding: '10px'
            };
            if (currentUserRumpfbeugeErgebnis.length > 0) {
                rumpfbeugeErgebnisInput = (
                    <div>
                        <h4>Gespeicherte Ergebnisse</h4>
                        <h5 style={style}>Versuch 1: {currentUserRumpfbeugeErgebnis[0].versuch1}</h5>
                        <h5 style={style}>Versuch 2: {currentUserRumpfbeugeErgebnis[0].versuch2}</h5>
                    </div>
                );
            }
        }

        if (this.state.showSuccessAlert) {
            saveRumpfbeugeErgebnisButton = (
                <Alert color="success">Rumpfbeuge Ergebnis von {this.props.activeTeilnehmerVorname} wurde gespeichert!</Alert>
            );
        }

        return (
            <div>
                <Container>
                    <Row>
                        <Col xs="3">
                            <TestUserList
                                id="rumpfbeugeTeilnehmer"
                                testUsers={rumpfbeugeTeilnehmer}
                                selected={this.props.selected}
                                changed={(selected) => { this.activeTeilnehmerChangedHandler(selected) }} />
                        </Col>
                        <Col>
                            <h3>{this.props.activeTeilnehmerVorname} führt folgende Aufgabe durch:</h3>
                            {rumpfbeugeAnleitung}
                            {rumpfbeugeErgebnisInput}
                            {saveRumpfbeugeErgebnisButton}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };   
};

const mapStateToProps = state => {
    return {
        apiUri: state.apiUri,
        token: state.token,
        username: state.username
    };
  };

export default connect(mapStateToProps)(Rumpfbeuge);