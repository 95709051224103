import React from 'react';
import List from 'react-list-select';

const testUserList = (props) => {
    let testUsers = props.testUsers;
    return(
        <List
            items={testUsers.map(testUser => <div>{testUser.vorname} {testUser.nachname} {testUser.uebungAbsolviertSymbol}</div>)}
            selected={props.selected}
            multiple={false}
            onChange={props.changed}/>
    );
};

export default testUserList;