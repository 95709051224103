import React from 'react';
import { Input, InputGroup, InputGroupAddon, Button, InputGroupText } from 'reactstrap';

const handleFocus = (event) => event.target.select();

const laufElement = (props) => {
    return (
        <div>
            <InputGroup className="w-75">
                <Button onClick={() => props.onValueChange(props.value - 1)}>--</Button>
                <Button onClick={() => props.onValueChange(props.value - 0.1)}>-</Button>
                <Input
                    id={props.identifier}
                    className="w-50"
                    type="text"
                    step="0.1"
                    value={props.value}
                    onChange={(e) => props.onValueChange(e.target.value)}
                    onFocus={handleFocus}
                />
                <Button onClick={() => props.onValueChange(props.value + 0.1)}>+</Button>
                <Button onClick={() => props.onValueChange(props.value + 1)}>++</Button>
                
                    <InputGroupText>{props.unit}</InputGroupText>
               
                
            </InputGroup>
        </div>
    );
};

export default laufElement;