import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';

const BalancierenErgebnisInput = (props) => {
    const style = {
        display: 'block ruby',
        padding: '10px'
    };
    return (
        <div>
            <div style={style}>
                <h5 style={style}>Übung 1:</h5>
                <ButtonGroup>
                    <Button outline size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(1,0)} active={props.uebung1Ergebnis === 0}>0</Button>
                    <Button outline size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(1,1)} active={props.uebung1Ergebnis === 1}>1</Button>
                    <Button outline size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(1,2)} active={props.uebung1Ergebnis === 2}>2</Button>
                    <Button outline size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(1,3)} active={props.uebung1Ergebnis === 3}>3</Button>
                </ButtonGroup>
                <h5 style={style}>Versuche</h5>
            </div>
                <div style={style}>
                    <h5 style={style}>Übung 2:</h5>
                    <ButtonGroup>
                        <Button outline size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(2,0)} active={props.uebung2Ergebnis === 0}>0</Button>
                        <Button outline disabled={props.buttonsDisabled[1]} size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(2,1)} active={props.uebung2Ergebnis === 1}>1</Button>
                        <Button outline disabled={props.buttonsDisabled[1]} size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(2,2)} active={props.uebung2Ergebnis === 2}>2</Button>
                        <Button outline disabled={props.buttonsDisabled[1]} size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(2,3)} active={props.uebung2Ergebnis === 3}>3</Button>
                    </ButtonGroup>
                    <h5 style={style}>Versuche</h5>
                </div>
                <div style={style}>
                    <h5 style={style}>Übung 3:</h5>
                    <ButtonGroup>
                        <Button outline size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(3,0)} active={props.uebung3Ergebnis === 0}>0</Button>
                        <Button outline disabled={props.buttonsDisabled[2]} size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(3,1)} active={props.uebung3Ergebnis === 1}>1</Button>
                        <Button outline disabled={props.buttonsDisabled[2]} size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(3,2)} active={props.uebung3Ergebnis === 2}>2</Button>
                        <Button outline disabled={props.buttonsDisabled[2]} size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(3,3)} active={props.uebung3Ergebnis === 3}>3</Button>
                    </ButtonGroup>
                    <h5 style={style}>Versuche</h5>
                </div>
                <div style={style}>
                    <h5 style={style}>Übung 4:</h5>
                    <ButtonGroup>
                        <Button outline size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(4,0)} active={props.uebung4Ergebnis === 0}>0</Button>
                        <Button outline disabled={props.buttonsDisabled[3]} size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(4,1)} active={props.uebung4Ergebnis === 1}>1</Button>
                        <Button outline disabled={props.buttonsDisabled[3]} size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(4,2)} active={props.uebung4Ergebnis === 2}>2</Button>
                        <Button outline disabled={props.buttonsDisabled[3]} size="lg" color="primary" onClick={() => props.setBalancierenErgebnis(4,3)} active={props.uebung4Ergebnis === 3}>3</Button>
                    </ButtonGroup>
                    <h5 style={style}>Versuche</h5>
                </div>
                <div style={style}>
                    <h5 style={style}>Gesamt: {props.summe} Versuche</h5>
            </div>
            </div>
    );
};

export default BalancierenErgebnisInput;