import React from 'react';
import { Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';

const StandweitsprungErgebnisInput = (props) => {
    const style = {
        display: 'block ruby',
        padding: '10px'
    };
    return (
        <div>
            <div style={style}>
                <h5 style={style}>Versuch 1:</h5>
                <InputGroup className="w-25">
                    <Button onClick={() => props.onChange(1, props.StandweitsprungErgebnis[0] - 1)}>-</Button>
                    <Input className="w-25" type="number" id="versuch1Input" value={props.StandweitsprungErgebnis[0]} onChange={(e) => props.onChange(1, e.target.value)} />
                    <Button onClick={() => props.onChange(1, props.StandweitsprungErgebnis[0] + 1)}>+</Button>
                </InputGroup>
                <h5 style={style}>cm</h5>
            </div>
            <div style={style}>
                <h5 style={style}>Versuch 2:</h5>
                <InputGroup className="w-25">
                    <Button onClick={() => props.onChange(2, props.StandweitsprungErgebnis[1] - 1)}>-</Button>
                    <Input className="w-25" type="number" id="versuch2Input" value={props.StandweitsprungErgebnis[1]} onChange={(e) => props.onChange(2, e.target.value)} />
                    <Button onClick={() => props.onChange(2, props.StandweitsprungErgebnis[1] + 1)}>+</Button>
                </InputGroup>
                <h5 style={style}>cm</h5>
            </div>
        </div>
    );
};

export default StandweitsprungErgebnisInput;