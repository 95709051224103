import React, {Component} from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { Col, Container, Row, Alert } from 'reactstrap';
import TestUserList from '../TestUserList';
import {FaCheckCircle } from 'react-icons/fa';
import axios from 'axios';
import { connect } from 'react-redux';
import { getAuthToken } from '../../../store/utility';

import BalancierenErgebnisInput from './BalancierenErgebnisInput';

class Balancieren extends Component {
    state = {
        showSaveButton: true,
        showSuccessAlert: false,
        balancierenErgebnisse: [],
        BalancierenErgebnis: [0,0,0,0],
        ButtonsDisabled: [false, true, true, true]
    }

    componentWillReceiveProps() {
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Balancieren/GetBalancierenErgebnisseOfTest?pTestID=' + this.props.testID, { headers: { 'Authorization': `Bearer ${token}`}})
            .then(response => {
                this.setState({
                    balancierenErgebnisse: response.data
                });
            });
    }
    
    componentDidMount() {
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Balancieren/GetBalancierenErgebnisseOfTest?pTestID=' + this.props.testID, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => {
                this.setState({
                    balancierenErgebnisse: response.data
                });
            });
    }

    setBalancierenErgebnis = (uebung, selected) =>{
        let newBalancierenErgebnis = [...this.state.BalancierenErgebnis];
        let newButtonsDisabled = [false, true, true, true];
        newBalancierenErgebnis[uebung - 1] = selected;
        if(newBalancierenErgebnis[0] !== 0)
        {
            newButtonsDisabled[1] = false;
        }
        if(newBalancierenErgebnis[1] !== 0)
        {
            newButtonsDisabled[2] = false;
        }
        if(newBalancierenErgebnis[2] !== 0)
        {
            newButtonsDisabled[3] = false;
        }
        this.setState({
            BalancierenErgebnis: newBalancierenErgebnis,
            ButtonsDisabled: newButtonsDisabled
        });
    }

    activeTeilnehmerChangedHandler = (selected) => {
        let newBalancierenErgebnis = [0,0,0,0];
        let newButtonsDisabled = [false, true, true, true];
        this.setState({
            BalancierenErgebnis: newBalancierenErgebnis,
            ButtonsDisabled: newButtonsDisabled,
            showSaveButton: true,
            showSuccessAlert: false
        });
        this.props.changed(selected);
    }

    saveBalancierenErgebnis = () => {
        const data = [...this.state.BalancierenErgebnis];
        axios.post(this.props.apiUri + 'Balancieren?pKindID=' + this.props.activeTeilnehmerID + '&pTestID=' + this.props.testID + '', data, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                axios.get(this.props.apiUri + 'Balancieren/GetBalancierenErgebnisseOfTest?pTestID=' + this.props.testID, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                    .then(response => {
                        this.setState({
                            balancierenErgebnisse: response.data,
                            showSaveButton: false,
                            showSuccessAlert: true
                    });
                }) 
            })
    }

    render() {

        let showSaveButton = this.state.showSaveButton;
        let showSuccessAlert = this.state.showSuccessAlert;
        let balancierenErgebnisse = [...this.state.balancierenErgebnisse];
        let BalancierenErgebnis = [...this.state.BalancierenErgebnis];
        let balancierenAnleitung = (
            <div>
                <p>Balancieren auf der umgedrehten Langbank</p>
                <p>Setzen Sie das Ergebnis der jeweiligen Übung durch Klick der Zahlen.</p>
            </div>
        );
        let summe = 0;
        for (var i = BalancierenErgebnis.length; i--;) {
            summe += BalancierenErgebnis[i];
        }


        let balancierenTeilnehmer = [...this.props.testUsers];
        balancierenTeilnehmer.forEach(function (testUser) {
            if (balancierenErgebnisse.length > 0 && balancierenErgebnisse.some(ergebnis => ergebnis.kindID === testUser.id)) {
                testUser.uebungAbsolviertSymbol = <FaCheckCircle size={16} />;
                testUser.uebungAbsolviert = "JA";
            }
            else {
                testUser.uebungAbsolviert = "NEIN";
                testUser.uebungAbsolviertSymbol = "";
            }
        });

        let index = [...this.props.selected];
        if (balancierenTeilnehmer !== undefined && balancierenTeilnehmer[index[0]] !== undefined) {
            if (balancierenTeilnehmer[index[0]].uebungAbsolviert === "JA") {
                showSaveButton = false;
            }
        }

        let balanancierenErgebnisInput = null;
        let saveBalancierenErgebnisButton = null;
        if (showSaveButton) {
            balanancierenErgebnisInput = (
                <div>
                    <BalancierenErgebnisInput
                        setBalancierenErgebnis={(uebung, selected) => this.setBalancierenErgebnis(uebung, selected)}
                        uebung1Ergebnis={this.state.BalancierenErgebnis[0]}
                        uebung2Ergebnis={this.state.BalancierenErgebnis[1]}
                        uebung3Ergebnis={this.state.BalancierenErgebnis[2]}
                        uebung4Ergebnis={this.state.BalancierenErgebnis[3]}
                        buttonsDisabled={[...this.state.ButtonsDisabled]}
                        summe={summe}
                    />
                </div>
            );

            saveBalancierenErgebnisButton = (
                <div><button
                    className="btn btn-primary"
                    onClick={() => this.saveBalancierenErgebnis()}
                    type="button"> Ergebnis speichern
                </button>
                </div>
            );
        }
        else {
            let currentUsersBalancierenErgebnis = balancierenErgebnisse.filter(ergebnis => ergebnis.kindID === this.props.activeTeilnehmerID)
            const style = {
                display: 'block ruby',
                padding: '10px'
            };
            if (currentUsersBalancierenErgebnis.length > 0) {
                balanancierenErgebnisInput = (
                    <div>
                        <h4>Gespeicherte Ergebnisse</h4>
                        <h5 style={style}>Übung 1: {currentUsersBalancierenErgebnis[0].uebung1}</h5>
                        <h5 style={style}>Übung 2: {currentUsersBalancierenErgebnis[0].uebung2}</h5>
                        <h5 style={style}>Übung 3: {currentUsersBalancierenErgebnis[0].uebung3}</h5>
                        <h5 style={style}>Übung 4: {currentUsersBalancierenErgebnis[0].uebung4}</h5>
                        <h5 style={style}><b>Gesamt: </b>{
                            currentUsersBalancierenErgebnis[0].uebung1 +
                            currentUsersBalancierenErgebnis[0].uebung2 +
                            currentUsersBalancierenErgebnis[0].uebung3 +
                            currentUsersBalancierenErgebnis[0].uebung4} </h5>
                    </div>
                );
            }
        }

        if (showSuccessAlert) {
            saveBalancierenErgebnisButton = (
                <Alert color="success">Balancieren Ergebnis von {this.props.activeTeilnehmerVorname} wurde gespeichert!</Alert>
            );
        }

        return (
            <div>
                <Container>
                    <Row>
                        <Col xs="3">
                            <TestUserList
                                id="BalancierenTestUser"
                                testUsers={balancierenTeilnehmer}
                                selected={this.props.selected}
                                changed={(selected) => { this.activeTeilnehmerChangedHandler(selected) }} />
                        </Col>
                        <Col>
                            <h3>{this.props.activeTeilnehmerVorname} führt folgende Aufgabe durch:</h3>
                            {balancierenAnleitung}
                            {balanancierenErgebnisInput}
                            {saveBalancierenErgebnisButton}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        apiUri: state.apiUri,
        token: state.token,
        username: state.username
    };
  };

export default connect(mapStateToProps)(Balancieren);