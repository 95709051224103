import * as actionTypes from './actionTypes';
import axios from 'axios';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, username) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        username: username
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    //localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('username');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
}

export const auth = (preUrl, email, password, isRegister) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            username: email,
            password: password
        }
        let url = preUrl + 'Authentication/Register';
        if(!isRegister){
            url = preUrl + 'Authentication/Login';
        }
        axios.post(url, authData)
            .then(response =>{
                sessionStorage.setItem('token', response.data.data.token);
                sessionStorage.setItem('username', response.data.data.username);
                dispatch(authSuccess(response.data.data.token, response.data.data.username));
            })
            .catch(err =>{
                dispatch(authFail(err.response.data))
            });
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = sessionStorage.getItem('token');
        if(!token){
            dispatch(logout());
        }
        else{
            const username = sessionStorage.getItem('username');
            dispatch(authSuccess(token, username));
        }
    };
};