export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export function getAuthToken() {
    const token = sessionStorage.getItem('token');
    return token;
}