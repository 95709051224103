import React, { Component } from 'react';

import TrennLogo from '../components/Logos/SPORTKINDER BERLIN, Boxen.jpg';
import SportKinderBerlinLogo from '../components/Logos/Sport Kinder Berlin - Logo Slogan gr.jpg';
import AllianzLogo from '../components/Logos/Logo_Allianz_Kinder_Stiftungszeile_4c.jpg';
import AllianzLogo2 from '../components/Logos/kihifo-logo-4-300x151.png';
import Kooperationspartner from './Kooperationspartner/Kooperationspartner';
import BezirksamtSpandauLogo from '../components/Logos/2017_Logo_QPK_JA_quer.jpg';
import InFormLogo from '../components/Logos/LOGO-WIR-SIND-IN-FORM.jpg';
import BezirksamtSteglitzLogo from '../components/Logos/Bild1.jpg';
import { FaMapMarkedAlt, FaPhone, FaEnvelope, FaGlobeEurope } from 'react-icons/fa';
import { connect } from 'react-redux';

class Home extends Component {
    state = {

    };

    render() {
        let trennLogo = (
            <div>
                <img
                    alt=""
                    src={TrennLogo}
                    width="750"
                    height="40"
                    style={{ display: "block", marginLeft: "auto", marginRight: "auto", opacity: 0.4 }}
                />
            </div>
        );

        let sportKinderBerlinLogo = (
            <div>
                <img
                    alt=""
                    src={SportKinderBerlinLogo}
                    width="200"
                    height="120"
                />
            </div>
        );

        let informLogo = (
            <div>
                <img
                    alt=""
                    src={InFormLogo}
                    width="200"
                    height="120"
                />
            </div>
        );

        let sportKinderBerlin = (
            <div className="row">
                <div className="column">
                    {sportKinderBerlinLogo}
                </div>
                <div className="column">
                    {informLogo}
                </div>
            </div>

        );

        let kooperationspartner = (
            <div style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}>
                <div className="row" style={{ textAlign: "center", display: "block ruby" }}>
                    <div className="column">
                        <Kooperationspartner
                            src={AllianzLogo2}
                            cardWidth='20rem'
                            pictureWidth="250"
                            pictureHeight="120"
                            title="Allianz Berlin / Leipzig"
                        />
                    </div>
                    <div className="column">
                        <Kooperationspartner
                            src={AllianzLogo}
                            cardWidth='20rem'
                            pictureWidth="250"
                            pictureHeight="120"
                            title="Allianz M�nchen"
                        />
                    </div>
                </div>
                <br />
                <Kooperationspartner
                    src={BezirksamtSpandauLogo}
                    cardWidth='40rem'
                    pictureHeight="90"
                    title="Bezirksamt Spandau"
                />
                <br />
                <Kooperationspartner
                    src={BezirksamtSteglitzLogo}
                    pictureWidth="150"
                    pictureHeight="150"
                    cardWidth='40rem'
                    title="Bezirksamt Steglitz-Zehlendorf"
                />
            </div>
        );


        return (
            <div>
                <h1>DMT für Kleinkinder</h1>
                <p>
                    Willkommen auf der Programmoberfläche zur Erfassung der Daten des
                    Deutschen Motorik Tests für Kleinkinder. Unter dem Punkt "DMT-Konzeptgrundlage"
                    findet Ihr eine PDF, die euch einen Leitfaden zur Durchführung der einzelnen Teststationen
                    liefert.
                </p>
                <p>
                    Wir freuen uns, dass Ihr mit uns diese Datenbank mit weiteren Daten fällt
                    und die Leistungen von Kleinkindern erfasst. Wir erreichen mit diesem System
                    eine individuelle Grundlage ein Kind bestmöglich zu fördern und somit frühzeitig
                    den richtigen Förderbedarf zu erkennen.
                </p>
                <br />
                <br />
                <p>Bei Fragen wendet euch an:</p>
                <ul>
                    <li>Benjamin Jeremias</li>
                    <li>SPORTKINDER BERLIN e.V.</li>
                    <li>Sportfachlicher Leiter SpoKi und Leitender Bewegungscoach Spandau</li>
                    <li>B.A. Sport und angewandte Trainingswissenschaften</li>
                    <li><div style={{ display: 'flex', alignItems: 'center' }}><FaMapMarkedAlt style={{ margin: '5px', marginRight: '15px' }} size={38} /><span>Rilkepfad 3 | 14167 Berlin</span></div></li>
                    <li><div style={{ display: 'flex', alignItems: 'center' }}><FaPhone style={{ margin: '5px', marginRight: '15px' }} size={38} /><span>01747672727</span></div></li>
                    <li><div style={{ display: 'flex', alignItems: 'center' }}><FaEnvelope style={{ margin: '5px', marginRight: '15px' }} size={38} /><span>jeremias@sportkinder-berlin.de</span></div></li>
                    <li><div style={{ display: 'flex', alignItems: 'center' }}><FaGlobeEurope style={{ margin: '5px', marginRight: '15px' }} size={38} /><span>www.sportkinder-berlin.de</span></div></li>
                </ul>
                <br />
                {sportKinderBerlin}
                <br />
                <hr
                    style={{
                        color: "gray",
                        backgroundColor: "gray",
                        height: 1
                    }}
                />
                {trennLogo}
                <h5
                    style={{ textAlign: "center" }}>In Kooperation mit</h5>
                <br />
                {kooperationspartner}
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        token: state.token,
        username: state.username
    };
};

export default connect(mapStateToProps)(Home);