import React, { Component } from 'react';
import { Route, Routes, useLocation, useNavigate, useParams, } from 'react-router-dom';
import { Layout } from './components/Layout';
import Home from './components/Home';
import Stammdaten from './components/Stammdaten/Stammdaten';
import Ergebnisse from './components/Ergebnisse/Ergebnisse';
import StartTest from './components/Test/StartTest';
import Test from './components/Test/Test';
import Auth from './components/Auth/Auth';
import Logout from './components/Auth/Logout/Logout';
import Auswertung from './components/Auswertung/Auswertung';
import {connect} from 'react-redux';

import './custom.css'
import * as actions from './store/actions/index';

class App extends Component {
componentDidMount() {
  this.props.onTryAutoSignup();
}

  static displayName = App.name;

  render () {
    return (
        <Layout>
            <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/auth" element={<Auth/>} />
          <Route path="/logout" element={<Logout/>} />
          <Route path="/stammdaten" element={<Stammdaten/>} />
          <Route path="/ergebnisse" element={<Ergebnisse/>} />
          <Route path="/auswertung" element={<Auswertung/>} />
          <Route path="/test" element={<Test/>}/>
                <Route path="/start-test" element={<StartTest/>} />
            </Routes>
      </Layout>
    );
  };
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
        <Component
            {...props}
            location={location}
            params={params}
            navigate={navigate}
        />
    );
  }

  return ComponentWithRouterProp;
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  };
};

export default withRouter(connect(null,mapDispatchToProps)(App));