import React from 'react';
import {
    Card, CardBody,
    CardTitle, CardFooter
  } from 'reactstrap';

const kooperationspartner = (props) => {
    return(
            <Card style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: props.cardWidth}}>
                <CardBody>
                    <CardTitle>
                        <img
                            alt=""
                            src = {props.src}
                            width= {props.pictureWidth}
                            height= {props.pictureHeight}
                            style={{display: "block", marginLeft: "auto", marginRight: "auto"}}
                        />
                    </CardTitle>
                    <CardFooter>
                        <p style={{textAlign: "center"}}>{props.title}</p>
                    </CardFooter>
                </CardBody>
            </Card>
    );
};

export default kooperationspartner;