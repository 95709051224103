import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { FaUsers, FaBalanceScale, FaArrowsAltH, FaArrowsAltV, FaRulerHorizontal, FaStopwatch, FaCheckCircle } from 'react-icons/fa';
import List from 'react-list-select';
import { Col, Container, Row, Alert } from 'reactstrap';
import Balancieren from './Balancieren/Balancieren';
import Spruenge from './Spruenge/Spruenge';
import Rumpfbeuge from './Rumpfbeuge/Rumpfbeuge';
import Standweitsprung from './Standweitsprung/Standweitsprung';
import Lauf from './Lauf/Lauf';
import {Input, Label} from 'reactstrap';
import axios from 'axios';
import { Route, Routes, useLocation, useNavigate, useParams, } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAuthToken } from '../../store/utility';

class Test extends Component {
    state = {
        currentTestID: 1,
        kinder: [],
        testTeilnehmer: [],
        testTeilnehmerIndexes: [],
        activeTeilnehmer: null,
        activeTeilnehmerIndex: [],
        testBeschreibungDavor: '',
        testBeschreibungDanach: '',
        freierTest: false,
        testAbgeschlossen: false
    };

    constructor(props) {
        super(props);
        const token = getAuthToken();
        const { testID } = this.props.location.state;
        axios.get(this.props.apiUri + 'Test/GetTest?id=' + testID, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(response => {
                let test = { ...response.data };
                if (test.kitaID !== 0) {
                    // Wenn der Test an einer Kita stattfindet
                    axios.get(this.props.apiUri + 'Kind/GetKinderOfKita?pKitaID=' + test.kitaID, { headers: { 'Authorization': `Bearer ${token}` } })
                        .then(kinder => {
                            let kinderOfKita = kinder.data.map(kind => {
                                return { id: kind.id, nachname: kind.nachname, vorname: kind.vorname }
                            });
                            this.setState({
                                kinder: kinderOfKita,
                                currentTestID: test.id,
                                testBeschreibungDavor: test.beschreibungDavor
                            })
                        });
                }
                else {
                    // Wenn es ein freier Test ist
                    this.setState({
                        freierTest: true
                    });
                }

            }
        );
    }

    //componentWillMount() {
    //    const { testID } = this.props.location.state;
    //    axios.get(this.props.apiUri + 'Test/GetTest?id=' + testID, {headers: {'Authorization': `Bearer ${this.props.token}`}})
    //        .then(response => {
    //            let test = {...response.data};
    //            if(test.kitaID !== 0){
    //                // Wenn der Test an einer Kita stattfindet
    //                axios.get(this.props.apiUri + 'Kind/GetKinderOfKita?pKitaID=' + test.kitaID, {headers: {'Authorization': `Bearer ${this.props.token}`}})
    //                    .then(kinder => {
    //                        let kinderOfKita = kinder.data.map(kind => {
    //                            return {id: kind.id, nachname: kind.nachname, vorname: kind.vorname}
    //                        });
    //                        this.setState({
    //                            kinder: kinderOfKita,
    //                            currentTestID: test.id,
    //                            testBeschreibungDavor: test.beschreibungDavor
    //                    })
    //                });
    //            }
    //            else{
    //                // Wenn es ein freier Test ist
    //                this.setState({
    //                    freierTest: true
    //                });
    //            }
                
    //    });
    //}
    GetKinder() {
        return (this.state.kinder.map(kind => ({ id: kind.id, nachname: kind.nachname, vorname: kind.vorname })))
    }

    activateInitialTeilnehmer = (kind) => {
        this.setState({
            activeTeilnehmer: kind,
            activeTeilnehmerIndex: [0]
        });
    }

    activeTeilnehmerChangeHandler = (selected) => {
        let index = selected;
        let activeTeilnehmer = {...this.state.testTeilnehmer[index]};
                let activeTeilnehmerIndex = [];
        activeTeilnehmerIndex.push(index);
        this.setState({
            ergebnisOfCurrentUserIsSaved: false,
            activeTeilnehmer: activeTeilnehmer,
            activeTeilnehmerIndex: activeTeilnehmerIndex
        });
    }

    teilnehmerChangeHandler = (selected) => {
        var arrayLength = selected.length;
        let testTeilnehmerIndexes = selected;
        let testTeilnehmer = [];
        let kind = null;
        for (var i = 0; i < arrayLength; i++)
        {
            let index = selected[i];
            kind = {...this.state.kinder[index]};
            testTeilnehmer.push(kind);
        }
        this.setState({
            testTeilnehmer: testTeilnehmer,
            testTeilnehmerIndexes: testTeilnehmerIndexes,
        });
        if([...this.state.testTeilnehmer].length === 0 && kind != null){
            this.activateInitialTeilnehmer(kind);
        }
        if(testTeilnehmer.length === 0){
            this.setState({
                activeTeilnehmer: null
            });
        }        
    }

    testBeschreibungDanachChangedHandler = (value) => {
        this.setState({
            testBeschreibungDanach: value
        });
    }

    testAbschließen = () => {
        const token = getAuthToken();
        const Beschreibung = { BeschreibungDanach: this.state.testBeschreibungDanach };
        axios.put(this.props.apiUri + 'Test/FinishTest/' + this.state.currentTestID,
        Beschreibung, 
            { headers: { 'Authorization': `Bearer ${token}`}})
            .then(response => {
                this.setState({
                    testAbgeschlossen: true
                });
            });
    }

    render() {
        let kinder = [...this.state.kinder];
        let teilnehmerList = [...this.state.testTeilnehmer];
        let testTeilnehmerIndexes = [...this.state.testTeilnehmerIndexes];
        let activeTeilnehmer = {...this.state.activeTeilnehmer};
        let activeTeilnehmerIndex = [...this.state.activeTeilnehmerIndex];
        let testTabsDisabled = true;
        let testBeschreibungDavor = this.state.testBeschreibungDavor;
        let testBeschreibungDanach = this.state.testBeschreibungDanach;
        if(teilnehmerList.length > 0){
            testTabsDisabled = false;
        }
        let teilnehmer = (
            <Tab eventKey="teilnehmer" title={<span><FaUsers size={64} /><br />{" Teilnehmer"}</span>}>
                <br />
                <p>Welche Kinder dieser Kita nehmen am Test teil?</p>
                <br />
                <Container>
                        <Row>
                            <Col xs="3">
                                <List
                                    items={kinder.map(kind => <div>{kind.vorname} {kind.nachname}</div>)}
                                    selected={testTeilnehmerIndexes}
                                    multiple={true}
                                    onChange={(selected) => { this.teilnehmerChangeHandler(selected) }}/>
                            </Col>
                            <Col>
                                <p>Übung 1 sieht so und so aus</p>
                            </Col>
                        </Row>
                </Container>
                <br />
                <p>Zusätzliche Kinder?</p>
            </Tab>
        );

        let balancieren = (
            <Tab eventKey="balancieren" title={<span><FaBalanceScale size={64} /><br />{" Balancieren"}</span>}
                disabled={testTabsDisabled}>
                <br />
                <Balancieren
                    testUsers={teilnehmerList}
                    selected={activeTeilnehmerIndex}
                    changed={(selected) => { this.activeTeilnehmerChangeHandler(selected) }}
                    activeTeilnehmerVorname = {activeTeilnehmer.vorname}
                    activeTeilnehmerID = {activeTeilnehmer.id}
                    testID={this.state.currentTestID}/>
            </Tab>
        );

        let springen = (
            <Tab 
                eventKey="springen" 
                title={<div><FaArrowsAltH size={64} /><br />{" Hin- und Herspringen"}</div>}
                disabled={testTabsDisabled}>
                <br />
                <Spruenge
                    testUsers = {teilnehmerList}
                    selected = {activeTeilnehmerIndex}
                    changed={(selected) => { this.activeTeilnehmerChangeHandler(selected) }}
                    activeTeilnehmerVorname = {activeTeilnehmer.vorname}
                    activeTeilnehmerID = {activeTeilnehmer.id}
                    testID= {this.state.currentTestID}/>
            </Tab>
        );

        let rumpfbeuge = (
            <Tab 
                eventKey="rumpfbeuge" 
                title={<span><FaArrowsAltV size={64} /><br />{" Rumpfbeuge"}</span>}
                disabled={testTabsDisabled}>
                <br />
                <Rumpfbeuge
                    testUsers = {teilnehmerList}
                    selected = {activeTeilnehmerIndex}
                    changed={(selected) => { this.activeTeilnehmerChangeHandler(selected) }}
                    activeTeilnehmerVorname = {activeTeilnehmer.vorname}
                    activeTeilnehmerID = {activeTeilnehmer.id}
                    testID= {this.state.currentTestID}/>
            </Tab>
        );

        let standweitsprung = (
            <Tab 
                eventKey="standweitsprung"
                title={<span><FaRulerHorizontal size={64} /><br />{" Standweitsprung"}</span>}
                disabled={testTabsDisabled}>
                <br />
                <Standweitsprung
                    testUsers = {teilnehmerList}
                    selected = {activeTeilnehmerIndex}
                    changed={(selected) => { this.activeTeilnehmerChangeHandler(selected) }}
                    activeTeilnehmerVorname = {activeTeilnehmer.vorname}
                    activeTeilnehmerID = {activeTeilnehmer.id}
                    testID= {this.state.currentTestID}/>
            </Tab>
        );

        let lauf = (
            <Tab
                eventKey="lauf"
                title={<span><FaStopwatch size={64} /><br />{" 6-Minutenlauf"}</span>}
                disabled={testTabsDisabled}>
                <br />
                <Lauf
                    testUsers = {teilnehmerList}
                    selected = {activeTeilnehmerIndex}
                    changed={(selected) => { this.activeTeilnehmerChangeHandler(selected) }}
                    activeTeilnehmerVorname = {activeTeilnehmer.vorname}
                    activeTeilnehmerID = {activeTeilnehmer.id}
                    testID= {this.state.currentTestID}/>
            </Tab>
        );

        let beschreibungInput = (
            <div>
                <Label for="textBeschreibungDavor">Testbeschreibung davor:</Label>
                <p id="textBeschreibungDavor">{testBeschreibungDavor}</p>
                <Label for="textBeschreibungDanach">Testbeschreibung:</Label>
                <Input 
                    type="textarea" 
                    name="Beschreibung" 
                    id="textBeschreibungDanach" 
                    value={testBeschreibungDanach} 
                    onChange={(e) => this.testBeschreibungDanachChangedHandler(e.target.value)}
                    />
            </div>
        );

        let abschließenButton = null;
        if(!this.state.testAbgeschlossen){
            if (this.state.testBeschreibungDanach != '') {
                abschließenButton = (
                    <div><button
                        className="btn btn-primary"
                        onClick={() => this.testAbschließen()}
                        type="button">
                        Test jetzt abschließen
                    </button>
                    </div>
                );
            }
        }
        else{
            abschließenButton =  (
                    <Alert color="success">Testergebnisse wurden gespeichert! Test wurde erfolgreich abgeschlossen!</Alert>
                );
        }

        let abschließen = (
            <Tab 
                eventKey="abschließen"
                title={<span><FaCheckCircle size={64} /><br />{" Test abschließen"}</span>}
                disabled={testTabsDisabled}>
                    <br/>
                    {beschreibungInput}
                    <br/>
                    {abschließenButton}
            </Tab>
        );

        return (
            <div>
                <h2>Mototik-Test (ID {this.state.currentTestID})</h2>
                <br />
                <Tabs defaultActiveKey="teilnehmer" id="testTabs">
                    {teilnehmer}
                    {balancieren}
                    {springen}
                    {rumpfbeuge}
                    {standweitsprung}
                    {lauf}
                    {abschließen}
                </Tabs>
            </div>
        );
    };
};

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                location={location}
                params={params}
                navigate={navigate}
            />
        );
    }

    return ComponentWithRouterProp;
}

const mapStateToProps = state => {
    return {
        apiUri: state.apiUri,
        token: state.token,
        username: state.username
    };
  };

export default withRouter(connect(mapStateToProps)(Test));