import React, { Component } from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { connect } from 'react-redux';
import DmtLogo from '../components/Logos/Logo_DMT_Titel_cropped.jpg';
import SportKinderLogo from '../components/Logos/Sport Kinder Berlin - Logo klein jpg.jpg';

class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    let dmtLogo = (
      <div>
          <img
              alt=""
              src={DmtLogo}
              width="140"
              height="60"
              className=""
          />
      </div>
  );

  let sportKinderLogo = (
      <div>
          <img
              alt=""
              src={SportKinderLogo}
              width="100"
              height="50"
              className="d-inline-block align-top"
          />
      </div>
  );

    let authItem = (
      // <NavItem>
      //   <NavLink tag={Link} className="text-dark" to="/auth">Einloggen</NavLink>
      // </NavItem>
      <Nav.Link href="/auth">Einloggen</Nav.Link>
    );
    let stammdatenItem = null;
    let ergebnisseItem = null;
    let testItem = null;
    let auswertungItem = null;
    if(this.props.isAuth){
      authItem = ( 
        // <NavItem>
        //   <NavLink tag={Link} className="text-dark" to="/logout">{this.props.username} Ausloggen</NavLink>
        // </NavItem>
          <Nav.Link href="/logout">{this.props.username} Ausloggen</Nav.Link>
        );
      ergebnisseItem = (
        // <NavItem>
        //   <NavLink tag={Link} className="text-dark" to="/ergebnisse">Ergebnisse</NavLink>
        // </NavItem>
        
          <Nav.Link href="/ergebnisse">Ergebnisse</Nav.Link>
      );
      stammdatenItem = (
        // <NavItem>
        //   <NavLink tag={Link} className="text-dark" to="/stammdaten">Stammdaten</NavLink>
        // </NavItem>    
        <Nav.Link href="/stammdaten">Stammdaten</Nav.Link>   
      );
      testItem = (
        // <NavItem>
        //   <NavLink tag={Link} className="text-dark" to="/start-test">Test</NavLink>
        // </NavItem>
        <Nav.Link href="/start-test">Test</Nav.Link>
      );
      auswertungItem = (
        // <NavItem>
        //   <NavLink tag={Link} className="text-dark" to="/auswertung">Auswertung</NavLink>
        // </NavItem>
        <Nav.Link href="/auswertung">Auswertung</Nav.Link>
      );
    }
    return (
      <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">{dmtLogo}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            {authItem}
            {ergebnisseItem}
            {stammdatenItem}
            {testItem}
            {auswertungItem}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
  }
}

const mapStateToProps = state => {
  return {
      isAuth: state.token !== null,
      username: state.username
  };
};

export default connect(mapStateToProps)(NavMenu);
