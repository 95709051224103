import React, {Component} from 'react';
import Select from 'react-select';
import axios from 'axios';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import KitaEdit from './KitaEdit';
import DurchfuehrendeEdit from './DurchfuehrendeEdit';
import { connect } from 'react-redux';
import { Input, Alert } from 'reactstrap';
import { getAuthToken } from '../../store/utility';

class Stammdaten extends Component {
    state = {
        kitas: [],
        kinder: [],
        durchfuehrende: [],
        selectedKita: null,
        selectedKind:{
            vorname: '',
            nachname: '',
            geburtsjahr: undefined,
            geburtsmonat: undefined,
            geburtstag: undefined,
            geschlechtObjekt: {id: 0, bezeichnung: ""}
        },
        newKind:{
            vorname: '',
            nachname: '',
            geburtsjahr: undefined,
            geburtsmonat: undefined,
            geburtstag: undefined,
            geschlechtObjekt: {id: 0, bezeichnung: ""}
        },
        Geschlechter: [],
        updateKind: false,
        createNewKind: false,
        kindSelected: false,
        selectedDurchfuehrender: null,
        showKinder: false,
        file: '',
        createdKinder: [],
        showUploadKindSuccessAlert: false
    };

    constructor(props) {
        super(props);
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Kita', { headers: { 'Authorization': `Bearer ${token}` } })
            .then(response => {
                this.setState({ kitas: response.data });
            }).then(
                axios.get(this.props.apiUri + 'Test/GetAllDurchfuehrende', { headers: { 'Authorization': `Bearer ${token}` } })
                    .then(response => {
                        this.setState({ durchfuehrende: response.data });
                    }).then(
                        axios.get(this.props.apiUri + 'Geschlecht').then(response => {
                            this.setState({ Geschlechter: response.data });
                        }
                        )));
    }

    //componentDidMount(){
    //    axios.get(this.props.apiUri + 'Kita', {headers: {'Authorization': `Bearer ${this.props.token}`}})
    //        .then(response => {
    //            this.setState({kitas: response.data});
    //        }).then(
    //            axios.get(this.props.apiUri + 'Test/GetAllDurchfuehrende', {headers: {'Authorization': `Bearer ${this.props.token}`}})
    //            .then(response => {
    //                this.setState({durchfuehrende: response.data});
    //            }).then(
    //                axios.get(this.props.apiUri + 'Geschlecht').then(response => {
    //                this.setState({Geschlechter: response.data});}
    //        )));
    //}

    newKitaCreatedHandler = () => {
        axios.get(this.props.apiUri + 'Kita', {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                this.setState({
                    kitas: response.data,
                    selectedKita: null,
                    selectedKind:{
                        vorname: '',
                        nachname: '',
                        geburtsjahr: undefined,
                        geburtsmonat: undefined,
                        geburtstag: undefined,
                        geschlechtObjekt: {id: 0, bezeichnung: ""}
                    },
                    kindSelected: false,
                    kinder: [],
                    showKinder: false
                });
            }); 
    };

    newDurchfuehrenderCreated = () => {
        axios.get(this.props.apiUri + 'Test/GetAllDurchfuehrende', {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                this.setState({
                    durchfuehrende: response.data,
                    selectedDurchfuehrender: null
                });
            });
    }

    newKindCreated = () => {
        let selectedKind = {
            vorname: '',
            nachname: '',
            geburtsjahr: undefined,
            geburtsmonat: undefined,
            geburtstag: undefined,
            geschlechtObjekt: {id: 0, bezeichnung: ""}
        };
        axios.get(this.props.apiUri + 'Kind/GetKinderOfKita?pKitaID=' +  this.state.selectedKita.id, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                        .then(response => {
                            this.setState({
                                kinder: response.data,
                                selectedKind:selectedKind,
                                newKind: selectedKind,
                                showKinder: true,
                                createNewKind: false,
                                showUploadKindSuccessAlert: true
                            });
                        });
    }

    kitaChangeHandler = selectedKita => {
        let selectedKind = {
            vorname: '',
            nachname: '',
            geburtsjahr: undefined,
            geburtsmonat: undefined,
            geburtstag: undefined,
            geschlechtObjekt: {id: 0, bezeichnung: ""}
        };

        if(selectedKita != null){
            axios.get(this.props.apiUri + 'Kind/GetKinderOfKita?pKitaID=' +  selectedKita.id, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                        .then(response => {
                            this.setState({
                                kinder: response.data,
                                selectedKita: selectedKita,
                                selectedKind:selectedKind,
                                newKind: selectedKind,
                                showKinder: true,
                                kindSelected: false,
                                createNewKind: false,
                                showUploadKindSuccessAlert: false
                            });
                        });
        }
        else
        {
            this.setState({
                kinder: [],
                selectedKita: null,
                selectedKind: null,
                showKinder: false,
                kindSelected: false,
                createNewKind: false,
                showUploadKindSuccessAlert: false
            });
        }
    };

    kindChangeHandler = pSelectedKind => {
        let kindSelected = false;
        let selectedKind = {
            vorname: '',
            nachname: '',
            geburtsjahr: undefined,
            geburtsmonat: undefined,
            geburtstag: undefined,
            geschlechtObjekt: {id: 0, bezeichnung: ""}
        };
        if(pSelectedKind !== null){
            kindSelected = true;
            selectedKind = {...pSelectedKind};
            if(selectedKind.geburtsmonat === null || selectedKind.geburtsmonat === undefined){
                selectedKind.geburtsmonat = 0;
            }
            if(selectedKind.geburtstag === null || selectedKind.geburtstag === undefined){
                selectedKind.geburtstag = 0;
            }
        }
        this.setState({
            selectedKind: selectedKind,
            kindSelected: kindSelected,
            newKind: selectedKind,
            updateKind: false,
            showUploadKindSuccessAlert:false
        });
    };

    KindVornameChangedHandler = value => {
        let updatedKind = {...this.state.newKind};
        updatedKind.vorname = value;
        this.setState({
            newKind: updatedKind
        });
    }

    KindNachnameChangedHandler = value => {
        let updatedKind = {...this.state.newKind};
        updatedKind.nachname = value;
        this.setState({
            newKind: updatedKind
        });
    }

    KindGeschlechtChangedHandler = value => {
        let updatedKind = {...this.state.newKind};
        updatedKind.geschlechtObjekt = value;
        this.setState({
            newKind: updatedKind
        });
    }

    KindGeburtsjahrChangedHandler = value => {
        let updatedKind = {...this.state.newKind};
        updatedKind.geburtsjahr = parseInt(value);
        this.setState({
            newKind: updatedKind
        });
    }

    KindGeburtsmonatChangedHandler = value => {
        let updatedKind = {...this.state.newKind};
        updatedKind.geburtsmonat = parseInt(value);
        this.setState({
            newKind: updatedKind
        });
    }

    KindGeburtstagChangedHandler = value => {
        let updatedKind = {...this.state.newKind};
        updatedKind.geburtstag = parseInt(value);
        this.setState({
            newKind: updatedKind
        });
    }

    toggleUpdateKind = value => {
        this.setState({
            updateKind: value
        });
    }

    toggleCreateKind = value => {
        this.setState({
            createNewKind: value
        });
    }

    selectedDurchfuehrenderChangedHandler = selectedDurchfuehrender => {
        this.setState({
            selectedDurchfuehrender: selectedDurchfuehrender
        });
    }

    createNewKind = (kind) => {
        let selectedKita = {...this.state.selectedKita};
        if(kind.geburtstag === undefined && kind.geburtsmonat === undefined && kind.geburtsjahr === undefined)
        {
            axios.post(this.props.apiUri + 'Kind?pKitaID=' + selectedKita.id + '&pVorname=' + kind.vorname + '&pNachname=' + kind.nachname + '&pGeschlechtID=' + kind.geschlechtObjekt.id,null , {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                this.newKindCreated();
                this.setState({
                    createdKinder: response.data
                });
            }); 
        }
        if(kind.geburtstag === undefined && kind.geburtsmonat === undefined && kind.geburtsjahr !== undefined){
            axios.post(this.props.apiUri + 'Kind?pKitaID=' + selectedKita.id + '&pVorname=' + kind.vorname + '&pNachname=' + kind.nachname + '&pGeschlechtID=' + kind.geschlechtObjekt.id + '&pGeburtsjahr=' + kind.geburtsjahr,null , {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => 
                {
                    this.newKindCreated();
                    this.setState({
                        createdKinder: response.data
                    });
                }); 
        }
        if(kind.geburtstag === undefined && kind.geburtsmonat !== undefined && kind.geburtsjahr !== undefined){
            axios.post(this.props.apiUri + 'Kind?pKitaID=' + selectedKita.id + '&pVorname=' + kind.vorname + '&pNachname=' + kind.nachname + '&pGeschlechtID=' + kind.geschlechtObjekt.id + '&pGeburtsjahr=' + kind.geburtsjahr + '&pGeburtsmonat=' + kind.geburtsmonat,null , {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => 
                {
                    this.newKindCreated();
                    this.setState({
                        createdKinder: response.data
                    });
                });  
        }
        if(kind.geburtstag !== undefined && kind.geburtsmonat !== undefined && kind.geburtsjahr !== undefined){
            axios.post(this.props.apiUri + 'Kind?pKitaID=' + selectedKita.id + '&pVorname=' + kind.vorname + '&pNachname=' + kind.nachname + '&pGeschlechtID=' + kind.geschlechtObjekt.id + '&pGeburtsjahr=' + kind.geburtsjahr + '&pGeburtsmonat=' + kind.geburtsmonat + '&pGeburtstag=' + kind.geburtstag,null , {headers: {'Authorization': `Bearer ${this.props.token}`}})
                .then(response => 
                    {
                        this.newKindCreated();
                        this.setState({
                            createdKinder: [response.data]
                        });
                    }); 
        }
    }

    onFileChange = (event) => {
		this.setState({
			file: event.target.files[0]
		});
    }
    
    downloadMuster = () => {
        axios({
            url: this.props.apiUri + 'Kind/DownloadMuster',
            method: 'GET',
            responseType: 'blob', // important
            headers: {'Authorization': `Bearer ${this.props.token}`}
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'}));
            const link = document.createElement('a');
            link.href = url;
            link.download = "Kinder.xlsx";

            // link.setAttribute('download', this.state.selectedKind.nachname + '.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          });
    }

    uploadFileData = (event) => {
		event.preventDefault();
		let data = new FormData();
		data.append('file', this.state.file);
        axios.post(this.props.apiUri + 'Kind/UploadKinder?pKitaID=' + this.state.selectedKita.id, data, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                this.setState({
                    createdKinder: response.data,
                    showUploadKindSuccessAlert: true,
                    file: ''
                });
            return axios.get(this.props.apiUri + 'Kind/GetKinderOfKita?pKitaID=' +  this.state.selectedKita.id, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                this.setState({
                    kinder: response.data
                });
            });
            });


    }

    updateKind = (kind) => {
        console.log(this.props.token);
        if(kind.geburtstag === undefined && kind.geburtsmonat === undefined && kind.geburtsjahr === undefined)
        {
            axios.put(this.props.apiUri + 'Kind/UpdateKind?id=' + kind.id + '&pVorname=' + kind.vorname + '&pNachname=' + kind.nachname + '&pGeschlechtID=' + kind.geschlechtObjekt.id,null, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                // console.log(response);
                // this.setState({
                //     selectedKind: kind,
                //     updateKind: false
                // });
                axios.get(this.props.apiUri + 'Kind/GetKinderOfKita?pKitaID=' +  this.state.selectedKita.id, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                    .then(response => {
                        this.setState({
                        kinder: response.data,
                        selectedKind: kind,
                        updateKind: false
                    });
                })}); 
        }
        if(kind.geburtstag === undefined && kind.geburtsmonat === undefined && kind.geburtsjahr !== undefined)
        {
            axios.put(this.props.apiUri + 'Kind/UpdateKind?id=' + kind.id + '&pVorname=' + kind.vorname + '&pNachname=' + kind.nachname + '&pGeschlechtID=' + kind.geschlechtObjekt.id +  '&pGeburtsjahr=' + kind.geburtsjahr,null, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                // console.log(response);
                // this.setState({
                //     selectedKind: kind,
                //     updateKind: false
                // });
                axios.get(this.props.apiUri + 'Kind/GetKinderOfKita?pKitaID=' +  this.state.selectedKita.id, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                .then(response => {
                    this.setState({
                    kinder: response.data,
                    selectedKind: kind,
                    updateKind: false
                });
            })}); 
        }
        if(kind.geburtstag === undefined && kind.geburtsmonat !== undefined && kind.geburtsjahr !== undefined){
            axios.put(this.props.apiUri + 'Kind/UpdateKind?id=' + kind.id + '&pVorname=' + kind.vorname + '&pNachname=' + kind.nachname + '&pGeschlechtID=' + kind.geschlechtObjekt.id +  '&pGeburtsjahr=' + kind.geburtsjahr + '&pGeburtsmonat=' + kind.geburtsmonat,null, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                // console.log(response);
                // this.setState({
                //     selectedKind: kind,
                //     updateKind: false
                // });
                axios.get(this.props.apiUri + 'Kind/GetKinderOfKita?pKitaID=' +  this.state.selectedKita.id, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                .then(response => {
                    this.setState({
                    kinder: response.data,
                    selectedKind: kind,
                    updateKind: false
                });
            })}); 
        }
        if(kind.geburtstag !== undefined && kind.geburtsmonat !== undefined && kind.geburtsjahr !== undefined)
        {
            const updatedKind = { Vorname: kind.vorname, Nachname: kind.nachname, GeschlechtID: kind.geschlechtObjekt.id, Geburtsjahr: kind.geburtsjahr, Geburtsmonat: kind.geburtsmonat, Geburtstag: kind.geburtstag}
            axios.put(this.props.apiUri + 'Kind/' + kind.id,
            //  + '&pVorname=' + kind.vorname + '&pNachname=' + kind.nachname + '&pGeschlechtID=' + kind.geschlechtObjekt.id +  '&pGeburtsjahr=' + kind.geburtsjahr + '&pGeburtsmonat=' + kind.geburtsmonat + '&pGeburtstag=' + kind.geburtstag,null,
            updatedKind,
            {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                // console.log(response);
                // this.setState({
                //     selectedKind: kind,
                //     updateKind: false
                // });
                axios.get(this.props.apiUri + 'Kind/GetKinderOfKita?pKitaID=' +  this.state.selectedKita.id, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                .then(response => {
                    this.setState({
                    kinder: response.data,
                    selectedKind: kind,
                    updateKind: false
                });
            })}); 
        }
    }

    CreateStringFromIndizes = (Indizes) => {
        return Indizes.join(' , ');
    }

    render(){
        const kitas = [...this.state.kitas];
        const kinder = [...this.state.kinder];

        let kitaList = (
            <div>
                <Select
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    options={kitas}
                    onChange={(event) => this.kitaChangeHandler(event)}
                    value={this.state.selectedKita}
                    isClearable='true'/>
            </div>
        );

        let kinderList = null;
        if(this.state.showKinder){
            kinderList = (
                <div>
                    <h3>Kinder</h3>
                    <Select 
                        getOptionLabel={option => `${option.vorname} ${option.nachname}`}
                        getOptionValue={option => option.id}
                        options={kinder}
                        onChange={(event) => this.kindChangeHandler(event)}
                        value={this.state.selectedKind}
                        isClearable='true'/>
                </div>
            );
        }

        let kitaSelected = false;
        if(this.state.selectedKita !== null){
            kitaSelected = true;
        }
        let kitaEdit = null;
        let kindEdit = null;
        if(this.state.selectedKita === null)
        {
            kitaEdit = (
                <div>
                    <KitaEdit
                        newKitaCreated={() => {this.newKitaCreatedHandler()}}
                        kitaSelected={kitaSelected}
                        selectedKita={this.state.selectedKita}/>
                </div>
            );
        }

        if(this.state.selectedKita !== null)
        {
            if(this.state.kindSelected)
            {
                if(!this.state.updateKind){
                    kindEdit = (
                        <div>
                            <button
                                className="btn btn-primary"
                                onClick={() => this.toggleUpdateKind(true)}
                                type="button"> Kind bearbeiten
                            </button>
                            <br/>
                            <br/>
                            <form>
                                <div className="form-group row">
                                    <label htmlFor="Vorname" className="col-sm-1 col-form-label">Vorname</label>
                                    <div className="col-sm-5">
                                        <Input readOnly type="text" id="Vorname" value={this.state.selectedKind.vorname}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="Nachname" className="col-sm-1 col-form-label">Nachname</label>
                                    <div className="col-sm-5">
                                        <Input readOnly type="text" id="Nachname" value={this.state.selectedKind.nachname}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="Geschlecht" className="col-sm-1 col-form-label">Geschlecht</label>
                                    <div className="col-sm-2">
                                        <Input readOnly type="text" id="Geschlecht" value={this.state.selectedKind.geschlechtObjekt.bezeichnung}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="Geburtsjahr" className="col-sm-1 col-form-label">Geburtsjahr</label>
                                    <div className="col-sm-2">
                                        <Input readOnly type="number" id="Geburtsjahr" value={this.state.selectedKind.geburtsjahr}/>
                                    </div>
                                    <label htmlFor="Geburtsmonat" className="col-sm-1 col-form-label">Monat</label>
                                    <div className="col-sm-2">
                                        <Input readOnly type="number" id="Geburtsmonat" value={this.state.selectedKind.geburtsmonat}/>
                                    </div>
                                    <label htmlFor="Geburtstag" className="col-sm-1 col-form-label">Tag</label>
                                    <div className="col-sm-2">
                                        <Input readOnly type="number" id="Geburtstag" value={this.state.selectedKind.geburtstag}/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    );
                }
                else {
                    kindEdit = (
                        <div>
                            <button
                            className="btn btn-primary"
                            onClick={() => this.toggleUpdateKind(false)}
                            type="button"> Kind nicht mehr bearbeiten
                            </button>
                            <br/>
                            <br/>
                            <form>
                                <div className="form-group row">
                                    <label htmlFor="Vorname" className="col-sm-1 col-form-label">Vorname</label>
                                    <div className="col-sm-5">
                                        <input type="text" className="form-control" id="Vorname" value={this.state.newKind.vorname} onChange={(e) => this.KindVornameChangedHandler(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="Nachname" className="col-sm-1 col-form-label">Nachname</label>
                                    <div className="col-sm-5">
                                        <input type="text" className="form-control" id="Nachname" value={this.state.newKind.nachname} onChange={(e) => this.KindNachnameChangedHandler(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="Geschlecht" className="col-sm-1 col-form-label">Geschlecht</label>
                                    <div className="col-sm-2">
                                        <Select 
                                            id="Geschlecht"
                                            getOptionLabel={option => `${option.bezeichnung}`}
                                            getOptionValue={option => option.id}
                                            options={this.state.Geschlechter}
                                            onChange={(value) => this.KindGeschlechtChangedHandler(value)}
                                            value={this.state.newKind.geschlechtObjekt}
                                            isClearable='true'/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="Geburtsjahr" className="col-sm-1 col-form-label">Geburtsjahr</label>
                                    <div className="col-sm-2">
                                        <input type="number" className="form-control" id="Geburtsjahr" value={this.state.newKind.geburtsjahr} onChange={(e) => this.KindGeburtsjahrChangedHandler(e.target.value)}/>
                                    </div>
                                    <label htmlFor="Geburtsmonat" className="col-sm-1 col-form-label">Monat</label>
                                    <div className="col-sm-2">
                                        <input type="number" className="form-control" id="Geburtsmonat" value={this.state.newKind.geburtsmonat} onChange={(e) => this.KindGeburtsmonatChangedHandler(e.target.value)}/>
                                    </div>
                                    <label htmlFor="Geburtstag" className="col-sm-1 col-form-label">Tag</label>
                                    <div className="col-sm-2">
                                        <input type="number" className="form-control" id="Geburtstag" value={this.state.newKind.geburtstag} onChange={(e) => this.KindGeburtstagChangedHandler(e.target.value)}/>
                                    </div>
                                </div>
                                
                                <div className="form-group row">
                                    <div className="col-sm-10">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => this.updateKind(this.state.newKind)}
                                        type="button"> Änderungen speichern
                                    </button>
                                    </div>
                                </div>
                            </form>
                        </div>


                    );
                }   
            }
            else{
                if(this.state.createNewKind){
                    let disabled = true;
                    if(this.state.newKind.vorname && this.state.newKind.nachname && this.state.newKind.geschlechtObjekt.id !== 0 && this.state.newKind.geburtsjahr){
                        disabled = false;
                    }
                    kindEdit = (
                        <form>
                            <div class="form-group row">
                                <label htmlFor="Vorname" className="col-sm-1 col-form-label">Vorname</label>
                                <div class="col-sm-5">
                                    <input type="text" className="form-control" id="Vorname" value={this.state.newKind.vorname} onChange={(e) => this.KindVornameChangedHandler(e.target.value)}/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label htmlFor="Nachname" className="col-sm-1 col-form-label">Nachname</label>
                                <div class="col-sm-5">
                                    <input type="text" className="form-control" id="Nachname" value={this.state.newKind.nachname} onChange={(e) => this.KindNachnameChangedHandler(e.target.value)}/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label htmlFor="Geschlecht" className="col-sm-1 col-form-label">Geschlecht</label>
                                <div className="col-sm-2">
                                    <Select 
                                        id="Geschlecht"
                                        getOptionLabel={option => `${option.bezeichnung}`}
                                        getOptionValue={option => option.id}
                                        options={this.state.Geschlechter}
                                        onChange={(value) => this.KindGeschlechtChangedHandler(value)}
                                        value={this.state.newKind.geschlechtObjekt}
                                        isClearable='true'/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="Geburtsjahr" className="col-sm-1 col-form-label">Geburtsjahr</label>
                                <div class="col-sm-2">
                                    <input type="number" className="form-control" id="Geburtsjahr" value={this.state.newKind.geburtsjahr} onChange={(e) => this.KindGeburtsjahrChangedHandler(e.target.value)}/>
                                </div>
                                <label htmlFor="Geburtsmonat" className="col-sm-1 col-form-label">Monat</label>
                                <div className="col-sm-2">
                                    <input type="number" className="form-control" id="Geburtsmonat" value={this.state.newKind.geburtsmonat} onChange={(e) => this.KindGeburtsmonatChangedHandler(e.target.value)}/>
                                </div>
                                <label htmlFor="Geburtstag" className="col-sm-1 col-form-label">Tag</label>
                                <div className="col-sm-2">
                                    <input type="number" className="form-control" id="Geburtstag" value={this.state.newKind.geburtstag} onChange={(e) => this.KindGeburtstagChangedHandler(e.target.value)}/>
                                </div>
                            </div>
                            
                            <div className="form-group row">
                                <div className="col-sm-10">
                                <button
                                    disabled={disabled}
                                    className="btn btn-primary"
                                    onClick={() => this.createNewKind(this.state.newKind)}
                                    type="button"> Neues Kind speichern
                                </button>
                                </div>
                            </div>
                        </form>
                    );
                }
                else{
                    kindEdit = (
                        <div>
                            <button
                                className="btn btn-primary"
                                onClick={() => this.toggleCreateKind(true)}
                                type="button"> Neues Kind anlegen
                            </button>
                            <br/>
                            <br/>
                            <h5>Mehrere Kinder zur Kita hinzufügen</h5>
                            <button
                                className="btn btn-primary" 
                                type="button" 
                                onClick={() => this.downloadMuster()}>
                                Muster
                            </button>
                            <button 
                                className="btn btn-primary" 
                                type="button" 
                                disabled={!this.state.file} 
                                onClick={(event) => this.uploadFileData(event)}>Neue Kinder anlegen</button>
                            <br/>
                            <br/>
                            <br/>
                            <input onChange={(event) => this.onFileChange(event)} type="file"></input>
                        </div>
                    );
                }

            }
        } 
        
        if(this.state.showUploadKindSuccessAlert){
            let AnzahlVersuchterHochgeladenerKinder = this.state.createdKinder.length;
            let AnzahlErfolgreichHochgeladenerKinder = this.state.createdKinder.filter(Boolean).length;
            let IndizesUnerfolgreichHochgeladenerKinder = this.state.createdKinder.flatMap((bool, index) => bool ? [] : index + 2);
            let AnzahlUnerfolgreichHochgeladenerKinder = AnzahlVersuchterHochgeladenerKinder - AnzahlErfolgreichHochgeladenerKinder;
            if(AnzahlUnerfolgreichHochgeladenerKinder > 0)
            {
                kindEdit= (
                    <Alert color="success">
                        <p>Es wurde versucht, {AnzahlVersuchterHochgeladenerKinder} Kind(er) hochzuladen:</p>
                        <p>Dabei wurde(n) {AnzahlErfolgreichHochgeladenerKinder} Kind(er) erfolgreich sowie {AnzahlUnerfolgreichHochgeladenerKinder} nicht hochgeladen</p>
                        <br/>
                        <p>Die Datensätze in den Zeilen {this.CreateStringFromIndizes(IndizesUnerfolgreichHochgeladenerKinder)} konnten nicht gelesen werden.</p>
                    </Alert>
                );
            }
            else
            {
                kindEdit= (
                    <Alert color="success">
                        <p>Es wurde versucht, {AnzahlVersuchterHochgeladenerKinder} Kind(er) hochzuladen:</p>
                        <p>Dabei wurde(n) {AnzahlErfolgreichHochgeladenerKinder} Kind(er) erfolgreich hochgeladen!</p>
                    </Alert>
                );
            }
            
        }
        
        let durchfuehrendeTab = (
            <Tab eventKey="durchfuehrende" title="Durchführende">
                <br />
                <h3>Durchführende</h3>
                <Select 
                    getOptionLabel={option => `${option.vorname} ${option.nachname}`}
                    getOptionValue={option => option.id}
                    options={this.state.durchfuehrende}
                    onChange={(event) => this.selectedDurchfuehrenderChangedHandler(event)}
                    value={this.state.selectedDurchfuehrender}
                    isClearable='true'/>
                <br/>
                <DurchfuehrendeEdit
                    newDurchfuehrenderCreated={() => {this.newDurchfuehrenderCreated()}}
                    selectedDurchfuehrender={this.state.selectedDurchfuehrender}/>
            </Tab>
        );

        let kitaTab = (
            <Tab eventKey="kitas" title="Kitas und Kinder">
                <br />
                <h3>Kitas</h3>
                {kitaList}
                <br/>
                {kinderList}
                <br/>
                {kitaEdit}
                {kindEdit}
            </Tab> 
        );
        
        return(
            <div>
                <h3>Stammdaten</h3>
                <br/>
                <Tabs defaultActiveKey="kitaTab" id="stammdatenTabs">
                    {kitaTab}
                    {durchfuehrendeTab}
                </Tabs>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        apiUri: state.apiUri,
        token: state.token,
        username: state.username
    };
  };

export default connect(mapStateToProps)(Stammdaten);