import React, {Component} from 'react';
import {Input, Nav, NavLink} from 'reactstrap';
import Select from 'react-select';
import axios from 'axios';
import { connect } from 'react-redux';

class KitaEdit extends Component{
    state = {
        Kita:{
            id: 0,
            name: '',
            bezirk: null
        },
        Bezirke: [],
        update: false,
        showInputFields: false
    }

    componentDidMount(){
        axios.get(this.props.apiUri + 'Bezirk')
            .then(response => {
                this.setState({Bezirke: response.data});
            });
    }

    kitaNameChangedHandler = (value) => {
        this.setState({
            Kita:{
                name: value,
                bezirk: this.state.Kita.bezirk
            }
        });
    }

    kitaBezirkChangedHandler = (value) => {
        this.setState({
            Kita: {
                name: this.state.Kita.name,
                bezirk: value
            }
        });
    }

    toggleShowKitaInputFields = () => {
        let newValue = !this.state.showInputFields;
        this.setState({
            showInputFields: newValue
        });
    }

    saveKita = () => {
        axios.post(this.props.apiUri + 'Kita?pName=' + this.state.Kita.name + '&pBezirkID=' + this.state.Kita.bezirk.id , null, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
            console.log(response);
            this.setState({
                Kita:{
                    name: '',
                    bezirk: null
                }
            });
        this.props.newKitaCreated();
        }); 
    }

    updateKita = (id) => {
        axios.put(this.props.apiUri + 'Kita/' + id + '?pName=' + this.state.Kita.name)
            .then(response => {
            console.log(response);
            this.setState({
                Kita:{
                    name: '',
                    bezirk: null
                }
            });
        this.props.newKitaCreated();
        }); 
    }

    render(){
        const style = {
            display: 'block ruby',
            padding: '10px'
        };
        let bezirke = [...this.state.Bezirke];
        let kitaObject = {...this.state.Kita};
        let kitaButtonText = 'Kita anlegen';
        let kitaHeaderText = 'Neue Kita anlegen'

        let saveKitaButton = (
            <div>
                <button
                    className="btn btn-primary"
                    onClick={() => this.saveKita()}
                    type="button"> {kitaButtonText}
                </button>
            </div>
        );
        let bezirkSelect = null;
        bezirkSelect = (
            <Select 
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                options={bezirke}
                width='500px'
                onChange={(event) => this.kitaBezirkChangedHandler(event)}
                value={this.state.Kita.bezirk}
                isClearable='true'/>
        );
        if(this.props.kitaSelected)
        {
            kitaObject = this.props.selectedKita;
            if(this.state.Kita.name !== kitaObject.name && this.state.Kita.name.length !== 0){
                kitaObject.name = this.state.Kita.name;
            }
            kitaButtonText = 'Kita bearbeiten';
            kitaHeaderText = 'Bestehende Kita bearbeiten';
            saveKitaButton = (
                <div>
                    <button
                        className="btn btn-primary"
                        onClick={() => this.updateKita(kitaObject.id)}
                        type="button"> {kitaButtonText}
                    </button>
                </div>
            );
            bezirkSelect= (
                <p>{kitaObject.bezirk}</p>
            );
        }
                
        let kitaInputFields = null;
        if(this.state.showInputFields){
            kitaInputFields = (
                <div>
                    <div style={style}>
                            <h5 style={style}>Name:</h5>
                            <Input className="w-50" type="text" id="kitaNameInput" value={kitaObject.name} onChange={(e) => this.kitaNameChangedHandler(e.target.value)}/>
                    </div>
                    <div>
                        <h5 style={style}>Bezirk:</h5>
                        {bezirkSelect}
                    </div>
                    <br/>
                    {saveKitaButton}
                    <br/>
                </div>
            );
        }
        
        return (
            <div>
                <Nav>
                    <NavLink href="#" onClick={() => this.toggleShowKitaInputFields()}><h5>{kitaHeaderText}</h5></NavLink> 
                </Nav>
                {kitaInputFields}
            </div>
        );
    }
    
};

const mapStateToProps = state => {
    return {
        apiUri: state.apiUri,
        token: state.token,
        username: state.username
    };
  };

export default connect(mapStateToProps)(KitaEdit);