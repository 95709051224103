import React, {Component} from 'react';
import { Col, Container, Row, Input, InputGroup, Button, Alert} from 'reactstrap';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import TestUserList from '../TestUserList';
import {FaCheckCircle } from 'react-icons/fa';
import axios from 'axios';
import { connect } from 'react-redux';
import Timer from '../../Timer/Timer';
import { getAuthToken } from '../../../store/utility';
import SpruengeErgebnisInput from './SpruengeErgebnisInput';

class Spruenge extends Component {
    state = {
        showSaveButton: true,
        showSuccessAlert: false,
        springenErgebnisse: [],
        SpruengeErgebnis: [0,0],
        timerIsRunning: false,
        timerKey: 0
    }
    
    componentWillReceiveProps() {
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Springen/GetSpringenErgebnisseOfTest?pTestID=' + this.props.testID, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => {
                this.setState({
                    springenErgebnisse: response.data
                });
            });
    }

    componentDidMount() {
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Springen/GetSpringenErgebnisseOfTest?pTestID=' + this.props.testID, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => {
                this.setState({
                    springenErgebnisse: response.data
                });
            });
    }

    SpruengeErgebnisChangedHandler = (uebung, value) =>{
        let newSpruengeErgebnis = [...this.state.SpruengeErgebnis];
        newSpruengeErgebnis[uebung - 1] = parseInt(value, 10);
        this.setState({
            SpruengeErgebnis: newSpruengeErgebnis
        });
    }

    activeTeilnehmerChangedHandler = (selected) => {
        let newSpruengeErgebnis = [0,0];
        this.setState({
            SpruengeErgebnis: newSpruengeErgebnis,
            showSaveButton: true,
            showSuccessAlert: false
        });
        this.props.changed(selected);
    }

    saveSpruengeErgebnis = () => {
        const data = [...this.state.SpruengeErgebnis];
        axios.post(this.props.apiUri + 'Springen?pKindID=' + this.props.activeTeilnehmerID + '&pTestID=' + this.props.testID + '', data, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                axios.get(this.props.apiUri + 'Springen/GetSpringenErgebnisseOfTest?pTestID=' + this.props.testID, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                    .then(response => {
                            this.setState({
                                springenErgebnisse: response.data,
                                showSaveButton: false,
                                showSuccessAlert: true
                            })
                        })
                })
    }

    startTimer = () => {
        this.setState({
            timerIsRunning: true
        });
    }

    timerComplete = () => {
        this.setState({
            timerIsRunning: false,
        });
    }

    showTimer = () => {
        this.setState({
            timerIsOpen: true
        });
    }

    hideTimer = () => {
        this.setState({
            timerIsRunning: false,
            timerIsOpen: false
        });
    }

    render() {
        let springenErgebnisse = [...this.state.springenErgebnisse];
        let showSaveButton = this.state.showSaveButton;

        let spruengeAnleitung = (
            <div>
                <p>Hin- und Herspringen</p>
                <p>Setzen Sie das Ergbnis des jeweiligen Versuchs in das Feld ein</p>
            </div>
        );

        let springenTeilnehmer = [...this.props.testUsers];
        springenTeilnehmer.forEach(function (testUser) {
            if (springenErgebnisse.length > 0 && springenErgebnisse.some(ergebnis => ergebnis.kindID === testUser.id)) {
                testUser.uebungAbsolviertSymbol = <FaCheckCircle size={16} />;
                testUser.uebungAbsolviert = "JA";
            }
            else {
                testUser.uebungAbsolviert = "NEIN";
                testUser.uebungAbsolviertSymbol = "";
            }
        });

        let index = [...this.props.selected];
        if (springenTeilnehmer !== undefined && springenTeilnehmer[index[0]] !== undefined) {
            if (springenTeilnehmer[index[0]].uebungAbsolviert === "JA") {
                showSaveButton = false;
            }
        }

        let saveSpruengeErgebnisButton = null;
        let spruengeErgebnisInput = null;
        if (showSaveButton) {
            spruengeErgebnisInput = (
                <div>
                    <SpruengeErgebnisInput
                        SpruengeErgebnis={this.state.SpruengeErgebnis}
                        onChange={(versuch, value) => this.SpruengeErgebnisChangedHandler(versuch, value)}
                    />
                </div>
            );
            saveSpruengeErgebnisButton = (
                <div>
                    <button
                        className="btn btn-primary"
                        onClick={() => this.saveSpruengeErgebnis()}
                        type="button"> Ergebnis speichern
                    </button>
                </div>
            );
        }
        else {
            let currentUserSpruengeErgebnisse = springenErgebnisse.filter(ergebnis => ergebnis.kindID === this.props.activeTeilnehmerID);
            const style = {
                display: 'block ruby',
                padding: '10px'
            };
            if (currentUserSpruengeErgebnisse.length > 0) {
                spruengeErgebnisInput = (
                    <div>
                        <h4>Gespeicherte Ergebnisse</h4>
                        <h5 style={style}>Versuch 1: {currentUserSpruengeErgebnisse[0].versuch1}</h5>
                        <h5 style={style}>Versuch 2: {currentUserSpruengeErgebnisse[0].versuch2}</h5>
                    </div>
                );
            }
        }

        if (this.state.showSuccessAlert) {
            saveSpruengeErgebnisButton = (
                <Alert color="success">Hin- und Herspringen Ergebnis von {this.props.activeTeilnehmerVorname} wurde gespeichert!</Alert>
            );
        }


        let timerStartButton = (
            <button
                className="btn btn-primary"
                onClick={() => this.startTimer()}
                type="button"> Timer starten
            </button>
        );

        let showTimerButton = null;
        if (this.state.timerIsOpen) {
            showTimerButton = (
                <div>
                    <Button onClick={() => this.hideTimer()}>Timer schließen</Button>
                    {timerStartButton}
                </div>
            );
        }
        else {
            showTimerButton = (
                <Button onClick={() => this.showTimer()}>Timer anzeigen</Button>
            );
        }

        let timerModal = null;
        if (this.state.timerIsOpen) {
            timerModal =
                (
                    <div>
                        <Timer
                            timerIsRunning={this.state.timerIsRunning}
                            duration={15}
                            onTimerComplete={() => this.timerComplete()}
                        />
                    </div>
                );
        }

        return (
            <div>
                <Container>
                    <Row>
                        <Col xs="3">
                            <TestUserList
                                id="SpringenTestUser"
                                testUsers={springenTeilnehmer}
                                selected={this.props.selected}
                                changed={(selected) => { this.activeTeilnehmerChangedHandler(selected) }} />
                        </Col>
                        <Col>
                            <h3>{this.props.activeTeilnehmerVorname} führt folgende Aufgabe durch:</h3>
                            {spruengeAnleitung}
                            {showTimerButton}
                            {timerModal}
                            {spruengeErgebnisInput}
                            {saveSpruengeErgebnisButton}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };    
};

const mapStateToProps = state => {
    return {
        apiUri: state.apiUri,
        token: state.token,
        username: state.username
    };
  };

export default connect(mapStateToProps)(Spruenge);