import React, { Component } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Popover } from 'react-tiny-popover';
import song from './sound.mp3';

class Timer extends Component {
    state = {
        timerKey: 0,
        play: false,
        audio: new Audio(song)
    }

    componentDidMount() {
        this.state.audio.addEventListener('ended', () => this.setState({ play: false }));
    }

    componentWillUnmount() {
        this.state.audio.removeEventListener('ended', () => this.setState({ play: false }));
    }

    timerComplete = () => {
        let timerKey = this.state.timerKey + 1;
        this.setState({
            timerKey: timerKey,
            play: !this.state.play
        }, () => {
            this.state.play ? this.state.audio.play() : this.state.audio.pause();
        });
        this.props.onTimerComplete();
    }

    render() {
        return (
            <div>
                <CountdownCircleTimer
                    isPlaying={this.props.timerIsRunning}
                    key={this.state.timerKey}
                    duration={this.props.duration}
                    size={150}
                    onComplete={() => this.timerComplete()}
                    colors={[
                        ['#004777', 0.33],
                        ['#F7B801', 0.33],
                        ['#A30000', 0.33],]}>
                    {({ remainingTime }) => <h1>{remainingTime}</h1>}
                </CountdownCircleTimer>
            </div>
        );
    };
};

export default Timer;