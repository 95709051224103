import React, {Component} from 'react';
import { Input, Col, Container, Row, Alert, CardGroup, InputGroup, InputGroupAddon, InputGroupText, Progress, Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import axios from 'axios';

import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import LaufElement from './LaufElement';
import Timer from '../../Timer/Timer';
import {FaCheckCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { getAuthToken } from '../../../store/utility';

class Lauf extends Component {
    state = {
        laufErgebnisse: [],
        gelaufeneRunden: [],
        extraMeter: [],
        rundenMeter: 0,
        timerIsRunning: false,
        timerKey: 0,
        showSuccessAlert: false,
    }

    componentWillReceiveProps(){
        // console.log(this.props.testUsers);
        // // axios.get('https://localhost:44342/api/Lauf/GetLaufErgebnisseOfTest?pTestID=' + this.props.testID)
        // //     .then(response => {
        // //         this.setState({
        // //             laufErgebnisse: response.data
        // //         });
        // //     });
    }
    componentDidMount() {
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Lauf/GetLaufErgebnisseOfTest?pTestID=' + this.props.testID, {headers: {'Authorization': `Bearer ${token}`}})
            .then(response => {
                this.setState({
                    laufErgebnisse: response.data
                });
            });
    }

    rundenMeterChangedHandler = (value) => {
        this.setState({
                rundenMeter: value
            });
    }

    teilnehmerLaufRundenIncreased = (id, index) => {
        let gelaufeneRunden = [...this.state.gelaufeneRunden];
        if(gelaufeneRunden[index] === undefined){
            gelaufeneRunden[index] = 1;
        }
        else{
            gelaufeneRunden[index] = gelaufeneRunden[index] + 1
        }
        this.setState({
            gelaufeneRunden: gelaufeneRunden
        });
    }

    teilnehmerLaufRundenDecreased = (id, index) => {
        let gelaufeneRunden = [...this.state.gelaufeneRunden];
        if (gelaufeneRunden[index] === undefined) {
            gelaufeneRunden[index] = undefined;
        }
        else {
            gelaufeneRunden[index] = gelaufeneRunden[index] - 1
        }
        this.setState({
            gelaufeneRunden: gelaufeneRunden
        });
    }

    extraMeterChanged = (value, index) => {
        console.log('value: ' + value + ', index: ' + index);
        let extraMeter = [...this.state.extraMeter];
        extraMeter[index] = value;
        this.setState({
            extraMeter: extraMeter
        })
    }

    activeTeilnehmerChangedHandler = (selected) => {
        this.setState({
            LaufErgebnis: 0
        });
        this.props.changed(selected);
    }

    saveLaufErgebnis = () => {
        let laufTeilnehmer = [...this.props.testUsers];
        let gelaufeneRunden = [...this.state.gelaufeneRunden];
        let extraMeter = [...this.state.extraMeter];
        var i;
        for (i = 0; i < laufTeilnehmer.length; i++) {
            if(extraMeter[i] !== undefined){
                axios.post(this.props.apiUri + 'Lauf?pKindID=' + laufTeilnehmer[i].id + '&pTestID=' + this.props.testID + '&pRundenMeter=' + this.state.rundenMeter + '&pAnzahlRunden=' + gelaufeneRunden[i] + '&pExtraMeter=' + extraMeter[i], null, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                    .then(response => {
                        this.setState({
                            showSuccessAlert: true
                        });
                    })
            }
        } 
    }

    startTimer = () => {
        this.setState({
            timerIsRunning: true
        });
    }

    timerComplete = () => {
        this.setState({
            timerIsRunning: false
        });
    }

    showTimer = () => {
        this.setState({
            timerIsOpen: true
        });
    }

    hideTimer = () => {
        this.setState({
            timerIsRunning: false,
            timerIsOpen: false
        });
    }

    render() {
        let laufErgebnisse = [...this.state.laufErgebnisse];

        let rundenMeterValue = this.state.rundenMeter;
        let rundenMeter = (
            <InputGroup>
                    <InputGroupText>Rundenlänge</InputGroupText>
                <Input type="number" id="rundenMeter" value={rundenMeterValue} onChange={(e) => this.rundenMeterChangedHandler(e.target.value)} />
                    <InputGroupText>m</InputGroupText>
            </InputGroup>
        );

        let laufTeilnehmer = [...this.props.testUsers];
        laufTeilnehmer.forEach(function (testUser) {
            if (laufErgebnisse.length > 0 && laufErgebnisse.some(ergebnis => ergebnis.kindID === testUser.id)) {
                testUser.uebungAbsolviert = <FaCheckCircle size={16} />;
            }
            else {
                testUser.uebungAbsolviert = "";
            }

        });
        let teilnemerList = (
            <CardGroup>
                {this.props.testUsers.map((teilnehmer, index) => {
                    return <LaufElement
                        key={teilnehmer.id}
                        teilnehmer={teilnehmer}
                        gelaufeneRunden={this.state.gelaufeneRunden[index]}
                        extraMeter={this.state.extraMeter[index]}
                        extraMeterChanged={(event) => this.extraMeterChanged(event.target.value, index)}
                        laufElementNumber={index + 1}
                        onIncrease={(event) => this.teilnehmerLaufRundenIncreased(teilnehmer.id, index)}
                        onDecrease={(event) => this.teilnehmerLaufRundenDecreased(teilnehmer.id, index)}
                    />
                })}
            </CardGroup>
        );

        let saveLaufErgebnisButton = null;
        if (!this.state.showSuccessAlert) {
            saveLaufErgebnisButton = (
                <div>
                    <button
                        className="btn btn-primary"
                        onClick={() => this.saveLaufErgebnis()}
                        type="button"> Ergebnis speichern
                    </button>
                </div>
            );
        }
        else {
            saveLaufErgebnisButton = (
                <Alert color="success">Laufergebnisse wurden gespeichert!</Alert>
            );
        }

        let timerStartButton = (
            <button
                className="btn btn-primary"
                onClick={() => this.startTimer()}
                type="button"> Timer starten
            </button>
        );

        let showTimerButton = null;
        if (this.state.timerIsOpen) {
            showTimerButton = (
                <div>
                    <Button onClick={() => this.hideTimer()}>Timer schließen</Button>
                    {timerStartButton}
                </div>
            );
        }
        else {
            showTimerButton = (
                <Button onClick={() => this.showTimer()}>Timer anzeigen</Button>
            );
        }

        let timerModal = null;
        if (this.state.timerIsOpen) {
            timerModal =
                (
                    <div>
                        <Timer
                            timerIsRunning={this.state.timerIsRunning}
                            duration={360}
                            onTimerComplete={() => this.timerComplete()}
                        />
                    </div>
                );
        }

        return (
            <div>
                <Container>
                    <Row>
                        <Col xs="4">
                            {rundenMeter}
                        </Col>
                        <Col>
                            {showTimerButton}
                            {timerModal}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            {teilnemerList}
                            <br />
                            {saveLaufErgebnisButton}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        apiUri: state.apiUri,
        token: state.token,
        username: state.username
    };
  };


export default connect(mapStateToProps)(Lauf);