import React, {Component} from 'react';
import Select from 'react-select';
import {Table, Form, Input, FormGroup, Button, ButtonGroup, Label} from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import { getAuthToken } from '../../store/utility';

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

class Ergebnisse extends Component{
    state = {
        kitajahre: [],
        kitas: [],
        tests: [],
        kinder: [],
        testErgebnisse: [],
        selectedKita: null,
        selectedTest: null,
        selectedKind: null,
        showTests: false,
        showIncludePretestCheckbox: false,
        showKinder: false,
        showTestErgebnisse: false,
        showKombiTestErgebnisse: false,
        testtype: 1,
        selectedKitajahr: null,
        testId: null
    }

    constructor(props) {
        super(props);
        const token = getAuthToken();
        axios.get(this.props.apiUri + 'Test/GetKitajahre?pTestTypeID=' + this.state.testtype, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(response => {
                this.setState({
                    kitajahre: response.data,
                    selectedKitajahr: response.data[response.data.length - 1]
                }, () => {
                    this.KitajahrChangedHandler(this.state.selectedKitajahr)
                });
            });
    }

    //componentDidMount() {
    //    console.log("token cdm: " + this.props.token);
    //    axios.get(this.props.apiUri + 'Test/GetKitajahre?pTestTypeID=' + this.state.testtype, {headers: {'Authorization': `Bearer ${this.props.token}`}})
    //        .then(response => {
    //            this.setState({
    //                kitajahre: response.data,
    //                selectedKitajahr: response.data[response.data.length - 1]
    //            }, () => {
    //                this.KitajahrChangedHandler(this.state.selectedKitajahr)
    //            });
    //        });
    //}

    setTestType = (selected) => {
        this.setState({
            testtype: selected,
            selectedKita: null,
            selectedKitajahr: null,
            showTestErgebnisse: false
        }, () => {
            axios.get(this.props.apiUri + 'Test/GetKitajahre?pTestTypeID=' + selected, {headers: {'Authorization': `Bearer ${this.props.token}`}})
            .then(response => {
                this.setState({kitajahre: response.data});
            });
        });
    };

    KitajahrChangedHandler = value => {
        this.setState({
            selectedKitajahr: value,
            selectedKita: null,
            showTestErgebnisse: false
        }, () => {
            if(this.state.selectedKitajahr != null){
                axios.get(this.props.apiUri + 'Kita/GetKitasWithTestsInYear?pKitajahr=' + this.state.selectedKitajahr.bezeichnung + '&pTestTypeID=' + this.state.testtype, {headers: {'Authorization': `Bearer ${this.props.token}`}})
                .then(response => {
                    this.setState({kitas: response.data});
                });
            }
        });
    }

    KitaChangedHandler = selectedKita => {
        if(selectedKita !== null){
            this.setState({
                selectedKita: selectedKita,
                selectedKind: null,
                showTestErgebnisse: false
            }, () => {
                axios({
                    url: this.props.apiUri + 'Test/GetTestsWithKinder',
                    method: 'GET',
                    params:{
                        'pKitaID': this.state.selectedKita.id,
                        'pTestTypeID': this.state.testtype,
                        'pKitajahr': this.state.selectedKitajahr.bezeichnung
                    },
                    headers: {'Authorization': `Bearer ${this.props.token}`}
                })
                .then(response => {
                    this.setState({
                        kinder: response.data
                    })});     
            });
        }
        else{
            this.setState({
                kinder: [],
                selectedKita: null,
                selectedKind: null,
                showTestErgebnisse: false
            });
        }
    };

    KindChangedHandler = (selectedKind, test) => {
        if(selectedKind !== null){
            if(this.state.showKombiTestErgebnisse){
                axios({
                    url: this.props.apiUri + 'Test/GetKombiTestErgebnisse',
                    params: {
                        'pPosttestID': this.state.testId,
                        'pKindID': selectedKind.id
                    },
                    method: 'GET',
                    headers: {'Authorization': `Bearer ${this.props.token}`}})
                .then(response => {
                    this.setState({
                        selectedKind: selectedKind,
                        testErgebnisse: response.data,
                        showTestErgebnisse: true
                    });
                });
            }
            else{
                this.setState({
                  selectedKind: selectedKind  
                });
                this.state.kinder.forEach(function (test) {
                    if (test.options.includes(selectedKind)){
                        axios({
                            url: this.props.apiUri + 'Test/GetTestErgebnisse',
                            params: {
                                'pKindID': selectedKind.id,
                                'pTestID': test.id
                            },
                            method: 'GET',
                            headers: {'Authorization': `Bearer ${this.props.token}`}
                        }).then(response => {
                            this.setState({
                                testErgebnisse: response.data,
                                showTestErgebnisse: true,
                                testId: test.id
                            });
                        });
                    }
                }, this);
            }
        }
        else{
            this.setState({
                selectedKind: null,
                testErgebnisse: [],
                showTestErgebnisse: false
            })
        }
    }

    getTestLabel = (test) => {
        return <div><p>{test.id} - {test.testtyp} - {test.beginn}</p></div>        
    } 

    checkBoxValueChangedHandler = value => {
        this.setState({
            showKombiTestErgebnisse: value
        }, () => {
            if(this.state.selectedKind !== null){
                this.KindChangedHandler(this.state.selectedKind);
            }
        });
    }

    urkundeErstellen = () => {
        axios({
            url: this.props.apiUri + 'Test/CreateUrkunde',
            params: {
                'pTestID': this.state.testId,
                'pKindID': this.state.selectedKind.id
            },
            method: 'GET',
            responseType: 'blob', // important
            headers: {'Authorization': `Bearer ${this.props.token}`}
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.state.selectedKind.nachname + '.pdf');
            document.body.appendChild(link);
            link.click();
          });
    }

    urkundenErstellen = () => {
        axios({
            url: this.props.apiUri + 'Test/CreateUrkunden',
            method: 'GET',
            params: {
                'pKitajahr': this.state.selectedKitajahr.bezeichnung, 
                'pTestTypeId': this.state.testtype, 
                'pKitaId': this.state.selectedKita.id
            },
            responseType: 'blob', // important
            headers: {'Authorization': `Bearer ${this.props.token}`}
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            today = yyyy + mm + dd;
            link.setAttribute('download', this.state.selectedKita.name + "_" + today + '.zip');
            document.body.appendChild(link);
            link.click();
          });
    }

    render(){
        const testErgebnisse = [...this.state.testErgebnisse];
        const showTestErgebnisse = this.state.showTestErgebnisse;
        const showKombiTestErgebnisse = this.state.showKombiTestErgebnisse;

        const BezirkLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        const TestLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        const styleObj = {
            marginRight: "auto"
        }

        let TestTypeSelection = (
            <div>
                <h3>Testtyp</h3>
                <ButtonGroup>
                    <Button outline size="md" color="primary" onClick={() => this.setTestType(1)} active={this.state.testtype === 1}>PRETEST</Button>
                    <Button outline size="md" color="primary" onClick={() => this.setTestType(2)} active={this.state.testtype === 2}>POSTTEST</Button>
                    <Button outline size="md" color="primary" onClick={() => this.setTestType(3)} active={this.state.testtype === 3}>FREIER TEST</Button>
                </ButtonGroup>
            </div>
        );
        

        let KitaJahrSelection = null;
        if(this.state.testtype != null){
            KitaJahrSelection = (
                <div>
                    <h3>Kitajahr</h3>
                    <Select 
                            id="Kitajahr"
                            getOptionLabel={option => `${option.bezeichnung}`}
                            getOptionValue={option => option.id}
                            options={this.state.kitajahre}
                            onChange={(value) => this.KitajahrChangedHandler(value)}
                            value={this.state.selectedKitajahr}
                            isClearable='true'
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'} />
                </div>
            );
        }

        let KitaSelection = null;
        if(this.state.selectedKitajahr != null){
            KitaSelection = (
                <div>
                    <h3>Kita</h3>
                    <Select
                        id="kitas"
                        defaultValue={null}
                        value={this.state.selectedKita}
                        getOptionLabel={option => `${option.name}`}
                        getOptionValue={option => option.id}
                        onChange={(value) => this.KitaChangedHandler(value)}
                        options={this.state.kitas}
                        classNamePrefix="select"
                        formatGroupLabel={BezirkLabel}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        isClearable='true'
                    />
                </div>
            );
        }
        
        let kinderList = null;
        if(this.state.selectedKita != null){
            kinderList = (
                <div>
                    <h3>Kinder mit Testergebnissen</h3>
                    <Select
                        id="kinder"
                        defaultValue={null}
                        value={this.state.selectedKind}
                        getOptionLabel={option => `${option.vorname} ${option.nachname}`}
                        getOptionValue={option => option.id}
                        onChange={(value, label) => this.KindChangedHandler(value, label)}
                        options={this.state.kinder}
                        classNamePrefix="select"
                        formatGroupLabel={TestLabel}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        isClearable='true'
                    />
                </div>
            );
        }

        let createUrkundenButton = null;
        if(this.state.selectedKita != null){
            createUrkundenButton = (
                <div>
                    <button
                        className="btn btn-primary"
                        onClick={() => this.urkundenErstellen()}
                        type="button">
                            Urkunden aller Kinder erstellen
                    </button>
                </div>
            );
        }

        let createUrkundeButton = null;
        let testErgebnis = null;
        let testErgebnisHeader = null;
        if(showTestErgebnisse){
            createUrkundeButton = (
                <div><button
                            className="btn btn-primary"
                            onClick={() => this.urkundeErstellen()}
                            type="button">
                                Urkunde erstellen
                        </button>
                        </div>
            );
            if(this.state.testtype == 2){
                testErgebnisHeader = (
                    <div>
                        <Form inline>
                            <h3 style={styleObj}>Testergebnis</h3>
                            <FormGroup check>
                                <Input
                                    type="checkbox"
                                    id="includePretestCheckbox"
                                    label="Ergebnisse des Pretests mit dazunehmen"
                                    onChange={(e) => this.checkBoxValueChangedHandler(e.target.checked)}
                                />
                                <Label check>Ergebnisse des Pretests mit dazunehmen</Label>
                            </FormGroup>
                            
                        </Form>
                    </div>
                );
            }
            else{
                testErgebnisHeader = (<h3>Testergebnis</h3>);
            }
            if(showKombiTestErgebnisse){
                testErgebnis = (
                    <div>
                        {testErgebnisHeader}
                    <Table striped>
                            <thead>
                                <tr>
                                <th>Übung</th>
                                <th>Ergebnis Pretest</th>
                                <th>Bewertung Pretest</th>
                                <th>Ergebnis Posttest</th>
                                <th>Bewertung Posttest</th>
                                </tr>
                            </thead>
                            <tbody>
                             
                        {testErgebnisse.map((testergebnis, index) => {
                            return (
                            <tr key={index}>
                                <th scope="row">{testergebnis.uebung}</th>
                                <td>{testergebnis.pretestErgebnis}</td>
                                <td>{testergebnis.pretestBewertung}, {testergebnis.pretestKuerzel}</td>
                                <td>{testergebnis.posttestErgebnis}</td>
                                <td>{testergebnis.posttestBewertung}, {testergebnis.posttestKuerzel}</td>
                            </tr>
                            );})}
                               {testErgebnis}
                            </tbody>
                        </Table>
                        </div>
                );
            }
            else{
                testErgebnis = (
                    <div>
                        {testErgebnisHeader}
                    <Table striped>
                            <thead>
                                <tr>
                                <th>Übung</th>
                                <th>Ergebnis</th>
                                <th>Bewertung</th>
                                <th>Stempel</th>
                                </tr>
                            </thead>
                            <tbody>
                             
                        {testErgebnisse.map((testergebnis, index) => {
                            return (
                            <tr key={index}>
                                <th scope="row">{testergebnis.uebung}</th>
                                <td>{testergebnis.ergebnis}</td>
                                <td>{testergebnis.bewertung}, {testergebnis.kuerzel}</td>
                                <td>{testergebnis.anzahlStempel}</td>
                            </tr>
                            );})}
                               {testErgebnis}
                            </tbody>
                        </Table>
                        </div>
                );
            }
        }

        return(
            <div>
                {TestTypeSelection}
                <br/>
                {KitaJahrSelection}
                <br/>
                {KitaSelection}
                <br/>
                {/* {includePretestCheckBox} */}
                <br/>
                {createUrkundenButton}
                <br/>
                {kinderList}
                <br/>
                {testErgebnis}
                {createUrkundeButton}
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        apiUri: state.apiUri,
        token: state.token,
        username: state.username
    };
  };

export default connect(mapStateToProps)(Ergebnisse);