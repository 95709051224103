import React from 'react';
import { Button, Col, Container, Row, Input, InputGroupText, InputGroup, InputGroupAddon, Card, CardBody, CardHeader, CardFooter, CardText } from 'reactstrap';
import useLongPress from '../../Inputs/useLongPress';

const LaufElement = (props) => {
    let teilnehmer = { ...props.teilnehmer };

    const onLongPress = () => {
        props.onDecrease();
    };

    const onClick = () => {
        props.onIncrease();
    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    return (
        <div>
            <Card className="card-item" style={{ width: '200px', margin: '5px' }}>
                <CardHeader style={{ cursor: 'pointer' }} {...longPressEvent}>
                    {props.laufElementNumber} {teilnehmer.vorname} {teilnehmer.nachname}
                </CardHeader>
                <CardBody style={{ cursor: 'pointer' }} {...longPressEvent}>
                    <CardText>Runden {props.gelaufeneRunden}</CardText>
                </CardBody>
                <CardFooter>
                    <InputGroup>
                        <Input type="number" value={props.extraMeter} onChange={props.extraMeterChanged} />
                            <InputGroupText>extra m</InputGroupText>
                    </InputGroup>
                </CardFooter>
            </Card>
        </div>
    );
};

export default LaufElement;