import React from 'react';

import DecimalInputWithButtons from '../../Inputs/DecimalInputWithButtons';

const RumpfbeugeErgebnisInput = (props) => {
    const style = {
        display: 'block ruby',
        padding: '10px'
    };
    return (
        <div>
            <div style={style}>
                <h5 style={style}>Versuch 1:</h5>
                <DecimalInputWithButtons
                    identifier="versuch1Input"
                    value={props.RumpfbeugeErgebnis[0]}
                    onValueChange={(value) => props.onChange(1, value)}
                    unit="cm"
                />

            </div>
            <div style={style}>
                <h5 style={style}>Versuch 2:</h5>
                <DecimalInputWithButtons
                    identifier="versuch2Input"
                    value={props.RumpfbeugeErgebnis[1]}
                    onValueChange={(value) => props.onChange(2, value)}
                    unit="cm"
                />
            </div>
        </div>
    );
};

export default RumpfbeugeErgebnisInput;