import React, {Component} from 'react';
import {Input, Button} from 'reactstrap';
import { connect } from 'react-redux';
import {Navigate} from 'react-router-dom';

import * as actions from '../../store/actions/index';
import Spinner from 'react-bootstrap/Spinner';

class Auth extends Component {
    state = {
        username: '',
        password: '',
        token: ''
    }

    usernameChangedHandler = event => {
        this.setState({
            username: event.target.value
        });
    }

    passwordChangedHandler = event => {
        this.setState({
            password: event.target.value
        })
    }

    registerUser = () => {
        this.props.onAuth(this.props.preUrl, this.state.username, this.state.password, true);
    }

    loginUser = () => {
        this.props.onAuth(this.props.preUrl, this.state.username, this.state.password, false);
    }

    render(){
        const style = {
            display: 'block ruby',
            padding: '10px'
        };
        let userInput = (
            <div>
                <div style={style}>
                    <h5 style={style}>Benutzername:</h5>
                    <Input className="w-50" type="text" id="UsernameInput" value={this.state.username} onChange={(e) => this.usernameChangedHandler(e)}/>
                </div>
                <div style={style}>
                    <h5 style={style}>Passwort:</h5>
                    <Input className="w-50" type="password" id="PasswordInput" value={this.state.password} onChange={(e) => this.passwordChangedHandler(e)}/>
                </div>
            </div>
        );

        if(this.props.loading){
            userInput = <Spinner animation="border" />
        }

        let errorMessage = null;

        if(this.props.error){
            errorMessage = (
                <p>{this.props.error.message}</p>
            );
        }

        let authRedirect = null;
        if(this.props.isAuthenticated){
            authRedirect = <Navigate to="/"/>
        }

        return (
            <div>
                {authRedirect}
                {errorMessage}
                {userInput}
                <br/>
                {/* <Button onClick={() => this.registerUser()}>Benutzer registrieren</Button> */}
                <Button onClick={() => this.loginUser()}>Benutzer anmelden</Button>
                {/* <br/>
                <p>Hallo {this.props.username}</p>
                <br/>
                <p>{this.props.token}</p> */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        error: state.error,
        isAuthenticated: state.token !== null,
        preUrl: state.apiUri
    };
  };

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (preUrl, email, password, isRegister) => dispatch(actions.auth(preUrl, email, password, isRegister))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);